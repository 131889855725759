import AboutIndex from "@/views/pages/about/Index";
import AboutCreate from "@/views/pages/about/Create";
import AboutEdit from "@/views/pages/about/Edit";
import AboutDetail from "@/views/pages/about/Detail";

export default [
  {
    path: "/about",
    name: "About",
    component: AboutIndex,
  },

  {
    path: "/about/create",
    name: "AboutCreate",
    component: AboutCreate,
  },

  {
    path: "/about/:id/show",
    name: "AboutShow",
    component: AboutDetail,
  },

  {
    path: "/about/:id/edit",
    name: "AboutEdit",
    component: AboutEdit,
  },
];