<template>
  <main class="auth-layout h-screen bg-slate-50 flex justify-center items-center">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: "AuthLayout"
}
</script>

<style scoped>

</style>