import ContinentsIndex from "@/views/continents/Index";
import ContinentsCreate from "@/views/continents/Create";
import ContinentsEdit from "@/views/continents/Edit";
import ContinentsDetail from "@/views/continents/Detail";

export default [
  {
    path: "/continents",
    name: "Continents",
    component: ContinentsIndex,
  },

  {
    path: "/continents/create",
    name: "ContinentsCreate",
    component: ContinentsCreate,
  },

  {
    path: "/continents/:id/show",
    name: "ContinentsShow",
    component: ContinentsDetail,
  },

  {
    path: "/continents/:id/edit",
    name: "ContinentsEdit",
    component: ContinentsEdit,
  },
];