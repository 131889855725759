<template>
    <div class="mb-6 w-full sm:w-1/2 md:w-full max-w-3xl">
        <label v-if="label" :class="labelStyles()">{{ label }}</label>

        <v-select
            label="name"
            :class="inputStyles()"
            :reduce="option => option.value"
            :options="options"
            :placeholder="placeholder"
            :disabled="disabled"
            v-model="value"
            @input="input"
            @option:selected="selected"
        />
        <p class="mt-2 text-sm text-red-600" v-if="errorMessage">
            {{ errorMessage }}
        </p>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    name: "InputBelongsTo",

    props: {
        belongsTo: {
            type: Object,
            default() {
                return {};
            },
        },
        size: {
            type: String,
            default() {
                return "base";
            },
        },
        errorMessage: {
            type: String,
            default() {
                return "";
            },
        },
        label: {
            type: String,
            default() {
                return "";
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
        modelValue: {
            default() {
                return '';
            },
        },
        placeholder: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            value: '',
            keywords: '',
            limit: 10,
            options: [],
        }
    },

    created() {
        this.initInput();
    },

    methods: {
        initInput() {
            this.loadOptions();

            if (this.modelValue) {
                this.loadValue();
            }
        },

        loadOptions() {
            this.$axios.get(`${this.belongsTo.model}/search`, {
                params: {
                    limit: this.limit,
                    keywords: this.keywords
                }
            })
                .then(res => {
                    if (res) {
                        this.options = [];
                        res.data.forEach(item => {
                            this.options.push({
                                name: item[this.belongsTo.name],
                                value: item.id,
                            });
                        });
                    }
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                });
        },

        loadValue() {
            this.$axios.get(`${this.belongsTo.model}/${this.modelValue}/show`)
                .then(res => {
                    if (res) {
                        this.value = {
                            name: res.data[this.belongsTo.name],
                            value: res.data.id,
                        };
                    }
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                });
        },

        selected(option) {
            this.$emit('update:modelValue', option.value);
            this.$emit('input', option.value);
        },

        inputStyles() {
            let style = `input-select block w-full rounded border
        focus:outline-offset-3 focus:outline-4`;

            if (this.size === 'base') {
                style += ` text-base p-2`;
            }

            if (this.size === 'sm') {
                style += ` text-sm p-2`;
            }

            if (this.size === 'lg') {
                style += ` text-lg p-2`
            }

            if (this.errorMessage) {
                style += ` bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 outline-red-500`
            } else {
                style += ` bg-slate-100 border-slate-200 text-black outline-blue-300 focus:bg-white`
            }

            return style;
        },

        labelStyles() {
            let labelStyle = `block mb-2 text-sm `;
            if (this.errorMessage) {
                labelStyle += ` text-red-600 `;
            }
            return labelStyle;
        },

        input: _.debounce(function (e) {
            if (e && e.target.value) {
                this.keywords = e.target.value;

                this.loadOptions();
            }
        }, 300),
    },
}
</script>

<style lang="scss">
.input-select .vs__dropdown-toggle {
    padding: 0;
    border: none;
}

.input-select .vs__selected-options {
    padding: 0;
}

.input-select .vs__selected {
    padding: 0;
    margin: 0;
}

.input-select .vs__search, .input-select .vs__search:focus {
    padding: 0;
    margin: 0;
    line-height: inherit;
}

.input-select .vs__dropdown-menu {
    padding: 0;
}

.input-select .vs__actions {
    padding: 0;
}
</style>