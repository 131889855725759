import Index from "@/views/dealer-groups/Index";
import Create from "@/views/dealer-groups/Create";
import Edit from "@/views/dealer-groups/Edit";
import Detail from "@/views/dealer-groups/Detail";

export default [
  {
    path: "/dealer-groups",
    name: "DealerGroups",
    component: Index,
  },

  {
    path: "/dealer-groups/create",
    name: "DealerGroupsCreate",
    component: Create,
  },

  {
    path: "/dealer-groups/:id/show",
    name: "DealerGroupsShow",
    component: Detail,
  },

  {
    path: "/dealer-groups/:id/edit",
    name: "DealerGroupsEdit",
    component: Edit,
  },
];