import * as getters from './getters.js';
import * as mutations from './mutations.js';
import * as actions from './actions.js';

const state = {
    model: 'charges',
    items: [],

    pagination: {
        limit: 20,
    },

    loading: false,

    viewIndexData: {
        pageTitle: 'Charges',
        tableHeads: [
            {title: 'Amount', key: 'amount'},
            {title: 'Comment', key: 'comment'},
            {title: 'Status', key: 'stripe_charge_status'},
        ],
        tableProps: {
            create: false,
            show: false,
            destroy: false,
            edit: false,
            group: false,
            search: false,
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};