<template>
    <modal
        :modal-id="modelId"
    >
        <template v-slot:title>
            <h1 class="font-medium">
                Select file with countries and packages for continent
            </h1>
        </template>

        <div class="flex">
            <input
                type="file"
                ref="file"
                @change="uploadFile"
            >
        </div>

        <template v-slot:actions>
            <btn
                class="mr-4"
                text="Upload"
                color="success"
                :loading="loading"
                @click="submit"
            />
            <btn
                text="Cancel"
                color="dark"
                @click="hideModal"
            />
        </template>
    </modal>
</template>

<script>
import Modal from "@/components/ui/modal/Modal";
import Btn from "@/components/ui/buttons/Btn";

export default {
    name: "UploadCountriesWithPackagesModal",

    components: {Modal, Btn},

    props: {
        model: {
            type: String,
            default() {
                return '';
            }
        },
    },

    data() {
        return {
            modelId: `UploadCountriesWithPackagesModal`,
            loading: false,

            continentId: null,
            file: null,
        }
    },

    created() {
        this.emitter.on(`show::${this.modelId}`, this.showModal);
        this.emitter.on(`hide::${this.modelId}`, this.hideModal);
    },

    beforeUnmount() {
        this.emitter.off(`show::${this.modelId}`, this.showModal);
        this.emitter.off(`hide::${this.modelId}`, this.hideModal);
    },

    methods: {
        showModal(data) {
            this.emitter.emit(`modal::show::${this.modelId}`);
            this.continentId = data;
        },

        hideModal() {
            this.emitter.emit(`modal::hide::${this.modelId}`);
        },

        submit() {
            const formData = new FormData();
            formData.append('file', this.file);

            this.$axios.post(`/continents/${this.continentId}/upload-countries-and-packages-file`, formData)
                .then(() => {
                    this.emitter.emit(`show.load-data::${this.model}`);
                    this.hideModal();
                });
        },

        uploadFile() {
            this.file = this.$refs.file.files[0];
        }
    }
}
</script>

<style scoped>

</style>