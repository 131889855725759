<template>
    <index-view
        :model="model"
    />
</template>

<script>
import IndexView from "@/components/views/index/Index";
import {mapGetters} from "vuex";
export default {
    name: "Index",
    components: {IndexView},
    computed: {
        ...mapGetters({
            model: 'charges/getModel',
        })
    },
}
</script>

<style scoped>

</style>