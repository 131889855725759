<template>
    <app-layout>
      <template v-for="(field, key) in fields" :key="key">
        <component
          :options="field.options"
          :label="field.title"
          :type="field.type"
          :labelText="field.title"
          :is="`input-${field.type}`"
          :error-message="errors && errors[field.key] ? errors[field.key][0] : ''"
          v-model="field.value"
        />
      </template>

      <btn
        text="Update"
        color="success"
        @click="submit"
      />
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import Btn from "@/components/ui/buttons/Btn";

export default {
    name: "UsersEdit",
    components: {Btn, AppLayout},
    data() {
        return {
            model: 'users',
            pageTitle: 'Edit User',
            fields: {},
            errors: null,
            loading: false,
        };
    },

    created() {
        this.loadData();

        this.$store.dispatch('setNavTitle', this.pageTitle);
    },

    methods: {
      loadData() {
        this.loading = true;
        this.$axios.get(`/${this.model}/${this.$route.params.id}/edit`)
          .then(res => {
            this.fields = res.data;
            this.loading = false;
          })
          .catch(e => {
            this.$toast.warning(e.data.message);
            this.loading = false;
          });
      },

      submit() {
        this.loading = true;
        let formData = new FormData;

        for(let key in this.fields) {
          if (this.fields[key].value) {
            formData.set(this.fields[key].key, this.fields[key].value);
          }
        }

        this.$axios.post(`/${this.model}/${this.$route.params.id}/update`, formData)
          .then(() => {
            this.$router.push(`/${this.model}`);
            this.loading = false;
          })
          .catch(e => {
            this.$toast.warning(e.data.message);

            if (e.status === 422) {
              this.errors = e.data.errors;
            }

            this.loading = false;
          });
      }
    }
};
</script>
