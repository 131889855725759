<template>
    <app-layout>
        <template v-if="loading">
            <div class="flex justify-center">
                <pulse-loader
                    :loading="loading"
                    color="#3b82f6"
                />
            </div>
        </template>

        <template v-if="!loading">
            <template v-for="(items, key) in data" :key="key">
                <h1 class="text-gray-500 font-bold text-sm font-normal mb-2">
                    {{ key.charAt(0).toUpperCase() + key.slice(1) }} settings
                </h1>

                <template v-for="setting in items" :key="setting.id">
                    <div class="columns-2 w-full md:w-4/6 mb-4">
                        <div class="">
                            {{ setting.title }}
                        </div>

                        <div class="">
                            <template v-if="setting.type === settingTypes[1].id">
                                <input-toggle
                                    v-model="setting.value"
                                />
                            </template>

                            <template v-if="setting.type === settingTypes[2].id">
                                <input-number
                                    v-model="setting.value"
                                />
                            </template>
                        </div>
                    </div>
                </template>

                <btn
                    text="Save"
                    color="success"
                    @click="save(key)"
                />
            </template>
        </template>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { SETTING_TYPES } from "@/views/settings/constants";
import InputToggle from "@/components/ui/inputs/InputToggle";
import InputNumber from "@/components/ui/inputs/InputNumber";
import Btn from "@/components/ui/buttons/Btn";

export default {
    name: "SettingsIndex",

    components: {Btn, InputNumber, InputToggle, AppLayout, PulseLoader},

    data() {
        return {
            model: 'settings',
            pageTitle: 'Settings',
            loading: false,
            data: [],
            settingTypes: SETTING_TYPES,
        }
    },

    created() {
        this.loadData();

        this.$store.dispatch('setNavTitle', this.pageTitle);
    },

    methods: {
        loadData() {
            this.loading = true;

            this.$axios.get(`/${this.model}`)
                .then(res => {
                    this.data = res.data;
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                    this.loading = false;
                });
        },

        save(key) {
            this.loading = true;

            this.$axios.post(`/${this.model}/update`, this.data[key])
                .then(res => {
                    this.data[key] = res.data;
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                    this.loading = false;
                });
        }
    }
}
</script>

<style scoped>

</style>