<template>
    <input @change="handleFileChange" type="file" class="mb-4" id="image-input">
    <btn class="mb-4" text="Upload banner" @click="uploadFile" />
    <img class='w-1/2' :src="image_src" alt="" />
</template>

<script>
import Btn from "../../../../components/ui/buttons/Btn";
import appConfigs from "../../../../configs/app.js";

export default {
    name: "Banner",
    components: { Btn },
    data() {
        return {
            image_file: null,
            name: "",
            serverUrl: appConfigs.server_url,
            image_src: null, // Add a new property to store the URL of the uploaded image
        };
    },
    methods: {
        handleFileChange(event) {
            this.image_file = event.target.files[0];
            this.image_src = URL.createObjectURL(this.image_file);
            this.name = this.image_file.name;
        },
        async uploadFile() {
            if (!this.image_file) {
                return;
            }

            try {
                const formData = new FormData();
                formData.append("image_src", this.image_file);
                formData.append("name", this.name);

                await this.$axios.post("pages/home-page/upload-image", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                this.$toast.success('Image uploaded successfully!');
            } catch (error) {
                console.error(error);
                this.$toast.warning(error.data.errors.image_src[0]);
            }
        },
    },
};
</script>
