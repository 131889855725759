import CountriesIndex from "@/views/countries/Index";
import CountriesCreate from "@/views/countries/Create";
import CountriesEdit from "@/views/countries/Edit";
import CountriesDetail from "@/views/countries/Detail";

export default [
  {
    path: "/countries",
    name: "Countries",
    component: CountriesIndex,
  },

  {
    path: "/countries/create",
    name: "CountriesCreate",
    component: CountriesCreate,
  },

  {
    path: "/countries/:id/show",
    name: "CountriesShow",
    component: CountriesDetail,
  },

  {
    path: "/countries/:id/edit",
    name: "CountriesEdit",
    component: CountriesEdit,
  },
];