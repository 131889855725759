import {axiosInstance as axios} from "@/plugins/axios";

export const setItems = (store, payload) => {
    store.commit('setItems', payload);
};

export const setPagination = (store, payload) => {
    let pagination = store.getters.getPagination;

    for (let key in payload) {
        if (key !== 'data') {
            pagination[key] = payload[key];
        }
    }

    store.commit('setPagination', pagination);
};

export const loadData = ({ state, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
        state.loading = true
        let params = {
            paginate: state.pagination.limit,
        }
        if (payload && payload.page && state.pagination.limit) {
            params.page = payload.page || 1
        }
        if (payload && payload.keywords) {
            params.keywords = payload.keywords
        }
        axios.get(`/${state.model}`, {
            params
        })
            .then(res => {
                if (state.pagination.limit) {
                    dispatch(`setPagination`, res.data)
                    dispatch(`setItems`, res.data.data)
                } else {
                    dispatch(`setItems`, res.data)
                }
                resolve(res)
                state.loading = false

            })
            .catch(e => {
                this.$toast.warning(e.data.message);
                reject(e)
                state.loading = false

            });
    })
}