<template>
    <main class="app-layout">
        <sidebar-index/>

        <div :class="contentStyles()">
            <navbar-index/>

            <div class="p-6">
                <slot></slot>
            </div>
        </div>
    </main>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarIndex from "../components/sidebar/SidebarIndex.vue";
import NavbarIndex from "../components/navbar/NavbarIndex.vue";

export default {
    name: "AppLayout",

    components: {
        NavbarIndex,
        SidebarIndex

    },

    computed: {
        ...mapGetters({
            hideSidebar: "getHideSidebar",
        }),
    },

    methods: {
        contentStyles() {
            let styles = "content bg-slate-50 min-h-screen";

            if (this.hideSidebar) {
                styles += " ml-0 sm:ml-16";
            } else {
                styles += " lg:ml-80 ml-16";
            }

            return styles;
        },
    },
};
</script>

<style lang="scss"></style>
