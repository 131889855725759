import ReportsIndex from "@/views/reports/Index";
import ReportsCreate from "@/views/reports/Create";
import ReportsEdit from "@/views/reports/Edit";
import ReportsDetail from "@/views/reports/Detail";

export default [
    {
        path: "/reports",
        name: "Reports",
        component: ReportsIndex,
    },

    {
        path: "/reports/create",
        name: "ReportsCreate",
        component: ReportsCreate,
    },

    {
        path: "/reports/:id/show",
        name: "ReportsShow",
        component: ReportsDetail,
    },

    {
        path: "/reports/:id/edit",
        name: "ReportsEdit",
        component: ReportsEdit,
    },
];