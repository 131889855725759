<template>
    <div class="mb-6 w-full max-w-3xl">
        <label v-if="label" :class="labelStyles()">{{ label }}</label>
        <input
            type="email"
            :placeholder="placeholder"
            :disabled="disabled"
            :class="inputStyles()"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
        >
        <p class="mt-2 text-sm text-red-600 " v-if="errorMessage">
            {{ errorMessage }}
        </p>
    </div>
</template>

<script>
export default {
    name: "InputEmail",
    props: {
        size: {
            type: String,
            default() {
                return 'base'
            }
        },
        errorMessage: {
            type: String,
            default() {
                return ''
            }
        },
        label: {
            type: String,
            default() {
                return ''
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false
            }
        },
        placeholder: {
            type: String,
            default: '',
        },
        modelValue: {
            type: String,
            default: null,
        }
    },
    methods: {
        inputStyles() {
            let style = `block w-full rounded border
        focus:outline-offset-3 focus:outline-4`;

            if (this.size === 'base') {
                style += ` text-base p-2`;
            }

            if (this.size === 'sm') {
                style += ` text-sm p-2`;
            }

            if (this.size === 'lg') {
                style += ` text-lg p-2`
            }

            if (this.errorMessage) {
                style += ` bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 outline-red-500`
            } else {
                style += ` bg-slate-100 border-slate-200 text-black outline-blue-300 focus:bg-white`
            }

            return style;
        },

        labelStyles() {
            let labelStyle = `block mb-2 text-sm `
            if (this.errorMessage) {
                labelStyle += ` text-red-600 `
            }
            return labelStyle
        }

    }
}
</script>

<style scoped>

</style>