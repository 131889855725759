import GiftCardsIndex from "@/views/gift-cards/Index";
import GiftCardsCreate from "@/views/gift-cards/Create";
import GiftCardsEdit from "@/views/gift-cards/Edit";
import GiftCardsDetail from "@/views/gift-cards/Detail";

export default [
    {
        path: "/gift-cards",
        name: "GiftCards",
        component: GiftCardsIndex,
    },

    {
        path: "/gift-cards/create",
        name: "GiftCardsCreate",
        component: GiftCardsCreate,
    },

    {
        path: "/gift-cards/:id/show",
        name: "GiftCardsShow",
        component: GiftCardsDetail,
    },

    {
        path: "/gift-cards/:id/edit",
        name: "GiftCardsEdit",
        component: GiftCardsEdit,
    },
];