<template>
    <section
        class="shadow-sm bg-white rounded-lg pt-4 pb-2 px-6 w-full md:w-1/2"
    >
        <template v-if="loading">
            <div class="flex justify-center">
                <grid-loader
                    color="#cbd5e1"
                />
            </div>
        </template>

        <template
            v-if="!loading"
        >
            <template
                v-for="(field, key) in viewDetailData.fields"
                :key="key"
            >
                <template v-if="field.type === 'badge'">
                    <detail-badge-field
                        :model="model"
                        :field="field"
                        :item="data"
                    />
                </template>

                <template v-else-if="field.type === 'date'">
                    <detail-date-field
                        :model="model"
                        :field="field"
                        :item="data"
                    />
                </template>

                <template v-else-if="!field.has_many">
                    <div class="mb-4">
                        <h1 class="font-medium text-xs text-slate-400">
                            {{ field.text }}
                        </h1>

                        <template v-if="!field.belongs_to">
                            <p class="text-base">
                                {{ data[field.key] ? data[field.key] : 'Not set' }}
                            </p>
                        </template>
                        <template v-if="field.belongs_to">
                            <p class="text-base">
                                {{ data[field.belongs_to] ? data[field.belongs_to][field.key] : 'Not set' }}
                            </p>
                        </template>
                    </div>
                </template>

                <template v-else-if="field.has_many">
                    <div class="mb-4">
                        <h1 class="font-medium text-xs text-slate-400">
                            {{ field.title }}
                        </h1>
                        <template
                            v-for="(item, hasManyKey) in data[field.has_many]"
                            :key="`${key}-pivot-${hasManyKey}`"
                        >
                            <div class="flex">
                                <p class="text-base mr-2">
                                    {{ item[field.key] ? item[field.key] : 'Not set' }}
                                </p>
                                <template
                                    v-for="(pivot, pivotKey) in field.pivots"
                                    :key="`${key}-${hasManyKey}-pivot-${pivotKey}`"
                                >
                                    <p class="text-base">
                                         {{ item.pivot[pivot.key] }}
                                    </p>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
            </template>
        </template>
    </section>
</template>

<script>
import GridLoader from "vue-spinner/src/GridLoader.vue";
import {mapState} from "vuex";
import DetailBadgeField from "@/components/views/detail/modules/fields/BadgeField";
import DetailDateField from "@/components/views/detail/modules/fields/DateField";

export default {
    name: "DetailView",

    components: {
        DetailDateField,
        DetailBadgeField,
        GridLoader
    },

    props: {
        model: {
            type: String,
            default() {
                return null;
            }
        },
    },

    data() {
        return {
            loading: true,
            data: {},
        }
    },

    computed: {
        ...mapState({
            viewDetailData (state, getters) {
                return getters[`${this.model}/getViewDetailData`]
            },
        })
    },

    created() {
        this.loadData();

        this.$store.dispatch('setNavTitle', this.viewDetailData.pageTitle);
    },

    methods: {
        loadData() {
            this.loading = true;

            this.$axios
                .get(`/${this.model}/${this.$route.params.id}/show`)
                .then((response) => {
                    this.data = response.data;
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                    this.loading = false;
                });
        },
    }
}
</script>

<style scoped>

</style>