import AffiliatesIndex from "@/views/affiliates/Index";
import AffiliatesCreate from "@/views/affiliates/Create";
import AffiliatesEdit from "@/views/affiliates/Edit";
import AffiliatesDetail from "@/views/affiliates/Detail";

export default [
  {
    path: "/affiliates",
    name: "Affiliates",
    component: AffiliatesIndex,
  },

  {
    path: "/affiliates/create",
    name: "AffiliatesCreate",
    component: AffiliatesCreate,
  },

  {
    path: "/affiliates/:id/show",
    name: "AffiliatesShow",
    component: AffiliatesDetail,
  },

  {
    path: "/affiliates/:id/edit",
    name: "AffiliatesEdit",
    component: AffiliatesEdit,
  },
];