<template>
    <td
        v-if="head && item"
        class="px-6 py-4"
    >
        <div
            :class="styleClasses"
        >
            {{ value }}
        </div>
    </td>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "BadgeColumn",

    props: {
        model: {
            type: String,
            default() {
                return null;
            },
        },
        head: {
            type: Object,
            default() {
                return null;
            }
        },
        item: {
            type: Object,
            default() {
                return null;
            }
        },
    },

    computed: {
        ...mapState({
            constants (state, getters) {
                return getters[`${this.model}/getConstants`]
            },
        }),

        styleClasses() {
            let constant = this.constants[this.head.constant][this.item[this.head.key]];
            let styles = ` lowercase text-xs font-medium inline-block px-2 pt-0.5 pb-1 rounded-full`;

            if (constant.colors) {
                if (constant.colors.bg) {
                    styles += ` ${constant.colors.bg}`;
                }
                if (constant.colors.text) {
                    styles += ` ${constant.colors.text}`;
                }
            } else {
                styles += ` bg-slate-300`;
            }

            return styles;
        },

        value() {
            return this.constants[this.head.constant][this.item[this.head.key]].label
        }
    },
}
</script>

<style scoped>

</style>