import HeadingIndex from "@/views/pages/headings/Index";
import HeadingCreate from "@/views/pages/headings/Create";
import HeadingEdit from "@/views/pages/headings/Edit";
import HeadingDetail from "@/views/pages/headings/Detail";

export default [
  {
    path: "/headings",
    name: "Headings",
    component: HeadingIndex,
  },

  {
    path: "/headings/create",
    name: "HeadingCreate",
    component: HeadingCreate,
  },

  {
    path: "/headings/:id/show",
    name: "HeadingShow",
    component: HeadingDetail,
  },

  {
    path: "/headings/:id/edit",
    name: "HeadingEdit",
    component: HeadingEdit,
  },
];