<template>
  <app-layout>
    <pacman-loader
        v-if="loading"
        :loading="loading"
        color="#3b82f6"
    />

    <template
        v-if="!loading"
    >
        <template v-for="(field, key) in fields" :key="key">
            <div class="mb-4">
                <h1 class="font-medium text-sm text-slate-400">{{ field.text }}</h1>

                <template v-if="!field.belongs_to">
                    <p class="text-xl">{{ data[field.key] }}</p>
                </template>

                <template v-if="field.belongs_to">
                    <p class="text-xl">{{ data[field.belongs_to][field.key] }}</p>
                </template>
            </div>
        </template>

        <div class="mb-4">
            <h1 class="font-medium text-sm text-slate-400">Dealers</h1>

            <table-index
                :model="dealerModel"
                :heads="dealerTableHeads"
                :items="data.dealers"
                :create="false"
                :edit="false"
                :destroy="false"
            />
        </div>
    </template>

  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';
import TableIndex from "@/components/ui/table/TableIndex";

export default {
  name: "DealersDetail",

  components: {
      TableIndex,
    AppLayout,
    PacmanLoader,
  },

    data() {
        return {
            model: 'dealer-groups',
            pageTitle: 'Dealer group details',
            loading: true,
            data: {},
            fields: [
                {text: 'Name', key: 'name'},
            ],

            dealerModel: 'dealers',
            dealerTableHeads: [
                {title: 'Name', key: 'name'},
            ],
        }
    },

  created() {
    this.loadData();

    this.$store.dispatch('setNavTitle', this.pageTitle);
  },

  methods: {
    loadData() {
      this.loading = true;
      this.$axios
          .get(`/${this.model}/${this.$route.params.id}/show`)
          .then((response) => {
            this.data = response.data;
            this.loading = false;
          })
          .catch(e => {
            this.$toast.warning(e.data.message);
            this.loading = false;
          });
    },
  },
};
</script>
