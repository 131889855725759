<template>
    <div class="flex justify-between mb-3 flex-wrap">
        <div class="w-1/2">
            <btn
                v-if="group"
                size="sm"
                text="Group"
                color="dark"
                @click="grouping"
            />

            <div
                v-if="viewIndexData && viewIndexData.tableProps && viewIndexData.tableProps.search"
                class="search"
            >
                <input-text
                    placeholder="Search"
                    size="sm"
                    v-model="search.keywords"
                    @input="inputSearch"
                />
            </div>
        </div>
        <div class="flex gap-2">
            <div v-if="upload">
                <input type="file" @change="handleFileUpload">
                <btn
                    color="green"
                    type="secondary"
                    v-if="upload"
                    size="sm"
                    text="Upload"
                    @click="uploadcsv"
                    class="h-full"
                />
            </div>
            <form v-if="itemsLimit" class="max-w-sm mx-auto">
                <select class="h-full px-2.5 border rounded-lg" v-model="selectedLimit" @change="changeLimit">
                    <option selected value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                </select>
            </form>
            <btn
                v-if="downloadExcel"
                color="green"
                type="secondary"
                size="sm"
                text="Download"
                @click="download"
            />
            <btn
                v-if="create"
                size="sm"
                text="Create new"
                @click="$router.push(`/${model}/create`)"
            />
        </div>
    </div>
</template>

<script>

import Btn from "../buttons/Btn.vue";
import _ from "lodash";
import {mapState} from "vuex";
export default {
    name: "TableActions",

    components: {
        Btn
    },

    props: {
        model: {
            type: String,
            default() {
                return '';
            }
        },
        create: {
            type: Boolean,
            default() {
                return true;
            }
        },
        downloadExcel: {
            type: Boolean,
            default() {
                return false;
            }
        },
        upload: {
            type: Boolean,
            default() {
                return false;
            }
        },
        itemsLimit: {
            type: Boolean,
            default() {
                return false;
            }
        },
        group: {
            type: Boolean,
            default() {
                return false;
            }
        },
    },

    data() {
        return {
            search: {
                keywords: ''
            },
            selectedLimit: '25',
            file: null
        }
    },

    computed: {
        ...mapState({
            viewIndexData (state, getters) {
                return getters[`${this.model}/getViewIndexData`]
            },
            viewChargesData (state, getters) {
                return getters[`${this.model}/getViewChargesData`]
            },
            pagination (state, getters) {
                return getters[`${this.model}/getPagination`]
            },
        })
    },

    methods: {
        changeLimit() {
            this.pagination.limit = this.selectedLimit
            this.$store.dispatch(`${this.model}/loadData`)
        },
        grouping() {
            this.emitter.emit(`show::${this.model}SelectDealerGroupModal`);
        },

        inputSearch: _.debounce(function (e) {
            if (e && e.target.value) {
                this.search.keywords = e.target.value;
            } else {
                this.search.keywords = null;
            }

            this.$emit('inputSearch', this.search.keywords)
        }, 300),
        download() {
            this.$axios({
                method: 'get',
                url: `/${this.model}/download-excel`,
                responseType: 'blob' // Устанавливаем responseType в 'blob' для получения файла как Blob
            })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'orders.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    console.error(error);
                });
        },
        handleFileUpload(event) {
            this.file = event.target.files[0];
        },
        uploadcsv() {
            if (!this.file) {
                console.error('Файл не выбран');
                return;
            }

            let formData = new FormData();
            formData.append('file', this.file);

            this.$axios.post(`${this.model}/upload-csv`, formData)
                .then(() => {
                    this.$toast.success('Packages changed successfully!');
                })
                .catch(error => {
                    this.$toast.error(error.response.data.message);
                });
        }
    }
}

</script>

<style scoped>

</style>