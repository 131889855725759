import * as getters from './getters.js';
import * as mutations from './mutations.js';
import * as actions from './actions.js';

const state = {
    model: 'affiliates',
    items: [],

    constants: {},

    pagination: {
        limit: 20,
    },

    loading: false,

    viewIndexData: {
        pageTitle: 'Affiliates',
        tableHeads: [
            {title: 'First name', key: 'first_name'},
            {title: 'Last name', key: 'last_name'},
            {title: 'Email', key: 'email'},
        ],
        tableProps: {
            create: true,
            show: true,
            destroy: true,
            edit: true,
            group: false,
            search: true,
            itemsLimit: false
        }
    },

    viewCreateData: {
        pageTitle: 'Create affiliate',
    },

    viewDetailData: {
        pageTitle: 'Affiliate details',
        fields: [
            {text: 'First name', key: 'first_name'},
            {text: 'Last name', key: 'last_name'},
            {text: 'Email', key: 'email'},
            {text: 'Role', key: 'role_name'},
            {text: 'Created date', key: 'created_at_humanized'},
        ],
    },

    viewEditData: {
        pageTitle: 'Edit affiliate',
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};