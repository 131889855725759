<template>
    <app-layout>
        <div class="flex justify-between gap-6 flex-wrap xl:flex-nowrap">
            <section
                class="shadow-sm bg-white rounded-lg pt-4 pb-4 px-6 w-full"
            >
                <div class="flex justify-between items-center mb-6 gap-10 flex-wrap">
                    <div>
                        <h3 class="font-medium text-xs text-slate-400">Order ID</h3>
                        <p>{{ data?.order_id }}</p>
                    </div>
                    <div>
                        <h3 class="font-medium text-xs text-slate-400">Order status</h3>
                        <p
                            class=" lowercase text-xs font-medium inline-block px-2 pt-0.5 pb-1 rounded-full text-white"
                            :class="data?.order_status === 3 ? 'bg-emerald-400' : data?.order_status === 1 ? 'bg-blue-400' : data?.order_status === 2 ? 'bg-yellow-400' : 'bg-red-400'"
                        >
                            {{ data?.order_status === 3 ? 'Finished' : data?.order_status === 1 ? 'New' : data?.order_status === 2 ? 'In process' : 'Error' }}
                        </p>
                    </div>
                </div>
                <div class="mb-6">
                    <h2 class="text-[#ee6c31] text-xl font-semibold mb-4">Customer details</h2>
                    <div class="flex justify-between items-center gap-10 flex-wrap">
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">First name</h3>
                            <p>{{ data?.first_name }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Last name</h3>
                            <p>{{ data?.last_name }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Email</h3>
                            <p>{{ data?.email }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Contact number</h3>
                            <p>{{ data?.contact_number }}</p>
                        </div>
                    </div>
                </div>
                <div class="mb-6">
                    <h2 class="text-[#ee6c31] text-xl font-semibold mb-4">Order details</h2>
                    <div class="flex items-center flex-wrap gap-10">
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Continent name</h3>
                            <p>{{ data?.continent_name }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Country name</h3>
                            <p>{{ data?.country_name }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Country package name</h3>
                            <p>{{ data?.country_package_name }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Pickup location name</h3>
                            <p>{{ data?.pickup_location_name }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Insurance status</h3>
                            <p
                                class=" lowercase text-xs font-medium inline-block px-2 pt-0.5 pb-1 rounded-full text-white"
                                :class="data?.insurance_status === 1 ? 'bg-emerald-400' : 'bg-red-400'"
                            >
                                {{ data?.insurance_status === 1 ? 'Active' : 'Not active' }}
                            </p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">From date</h3>
                            <p>{{ data?.from_date }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">To date</h3>
                            <p>{{ data?.to_date }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Number of days</h3>
                            <p>{{ data?.number_of_days }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">pickup_dt_tm</h3>
                            <p>{{ data?.pickup_dt_tm }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">pickup_time</h3>
                            <p>{{ data?.pickup_time }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Number of devices</h3>
                            <p>{{ data?.number_of_devices }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Insurance amount</h3>
                            <p>{{ data?.insurance_amount }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Coupon code</h3>
                            <p>{{ data?.coupon_code ? data?.coupon_code : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Coupon amount</h3>
                            <p>{{ data?.coupon_amount }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Gift Card code</h3>
                            <p>{{ data?.gift_card ? data?.gift_card.gift_card.code : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Gift Card used amount</h3>
                            <p>{{ data?.gift_card ? data?.gift_card.used_amount : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Plan amount</h3>
                            <p>{{ data?.plan_amount }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Total amount</h3>
                            <p>{{ data?.total_amount }}</p>
                        </div>

                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Return address</h3>
                            <p>{{ data?.return_address }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Return city</h3>
                            <p>{{ data?.return_city }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Return country</h3>
                            <p>{{ data?.return_country }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Return state</h3>
                            <p>{{ data?.return_state }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Return zip code</h3>
                            <p>{{ data?.return_zip_code }}</p>
                        </div>
                    </div>
                </div>
                <div class="mb-6">
                    <h2 class="text-[#ee6c31] text-xl font-semibold mb-4">Shipping address</h2>
                    <div class="flex justify-between items-center flex-wrap gap-10">
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Address</h3>
                            <p>{{ data?.address ? data?.address : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">City</h3>
                            <p>{{ data?.city ? data?.city : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">State</h3>
                            <p>{{ data?.state ? data?.state : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Country</h3>
                            <p>{{ data?.country ? data?.country : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">ZIP Code</h3>
                            <p>{{ data?.zip_code ? data?.zip_code : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Insurance status</h3>
                            <p
                                class=" lowercase text-xs font-medium inline-block px-2 pt-0.5 pb-1 rounded-full text-white"
                                :class="data?.is_delivery === 1 ? 'bg-emerald-400' : 'bg-red-400'"
                            >
                                {{ data?.is_delivery === 1 ? 'Yes' : 'Not' }}
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="flex items-center text-[#ee6c31] mb-4 gap-4 cursor-pointer" @click="isAdditionalVisible = !isAdditionalVisible">
                        <h2 class="text-[#ee6c31] text-xl font-semibold">
                            Additional information
                        </h2>
                        <fa-icon class="" icon="sort-down"/>
                    </div>
                    <div class="flex items-center flex-wrap gap-10" v-if="isAdditionalVisible">
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Customer api ID</h3>
                            <p>{{ data?.customer_api_id ? data?.customer_api_id : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Payment ID</h3>
                            <p>{{ data?.payment_id ? data?.payment_id : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Payment mode</h3>
                            <p>{{ data?.payment_mode ? data?.payment_mode : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Requested ip</h3>
                            <p>{{ data?.requested_ip ? data?.requested_ip : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Requested device</h3>
                            <p>{{ data?.requested_device ? data?.requested_device : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Transaction reference id</h3>
                            <p>{{ data?.transaction_reference_id ? data?.transaction_reference_id : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Requested os</h3>
                            <p>{{ data?.requested_os ? data?.requested_os : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Stripe customer id</h3>
                            <p>{{ data?.stripe_customer_id ? data?.stripe_customer_id : 'Not set' }}</p>
                        </div>
                        <div>
                            <h3 class="font-medium text-xs text-slate-400">Stripe payment response</h3>
                            <p>{{ data?.stripe_payment_response ? data?.stripe_payment_response : 'Not set' }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <div class="w-full">
                <div
                    class="bg-white rounded-lg py-4 px-6 mb-6"
                >
                    <p class="mb-2">Charges history</p>
                    <ChargesView />
                </div>
                <div
                    class="shadow-sm bg-white rounded-lg py-4 px-6"
                >
                    <div>
                        <input-number
                            label="Amount"
                            v-model="extraChargeValue"
                        />
                        <input-textarea
                            label="Comment"
                            v-model="comment"
                        />
                        <btn
                            size="sm"
                            text="Charge extra"
                            :loading="extraChargeLoading"
                            @click="extraCharge"
                        />
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "../../layouts/AppLayout.vue";
import {mapGetters} from "vuex";
import Btn from "@/components/ui/buttons/Btn";
import InputNumber from "@/components/ui/inputs/InputNumber";
import InputTextarea from "@/components/ui/inputs/InputTextarea";
import ChargesView from "@/views/charges/Index.vue";
export default {
    name: "OrdersDetail",

    components: {
        InputTextarea,
        InputNumber,
        Btn,
        AppLayout,
        ChargesView
    },

    data() {
        return {
            extraChargeValue: 0,
            extraChargeLoading: false,
            comment: '',
            data: null,
            isAdditionalVisible: false
        }
    },

    computed: {
        ...mapGetters({
            model: 'orders/getModel',
        })
    },
    created() {
        this.loadDetailData()
    },
    methods: {
        extraCharge() {
            this.extraChargeLoading = true;
            this.$axios.post(`orders/${this.$route.params.id}/extra-charge`, {
                amount: this.extraChargeValue,
                comment: this.comment
            }).then(() => {
                this.extraChargeLoading = false;
                this.extraChargeValue = 0;
                this.comment = ''
                this.loadData()
                this.$toast.success('Extra charge was successful');
            }).catch(e => {
                this.extraChargeLoading = false;
                this.$toast.warning(e.data.message);
            })
        },
        loadData() {
            this.$store.dispatch(`charges/loadData`)
        },
        loadDetailData() {
            this.loading = true;
            this.$axios
                .get(`/${this.model}/${this.$route.params.id}/show`)
                .then((response) => {
                    this.data = response.data;
                    console.log(response.data)
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                    this.loading = false;
                });
        },
    }
};
</script>
