import router from "../../router";

export default {
    state: () => ({
        navs: {
            home: {
                path: "/",
                icon: "house-chimney",
                label: "Home",
                routeName: "Home",
                active: false,
            },
        },
        hideSidebar: false,
    }),
    mutations: {
        setNavs(state, payload) {
            state.navs = payload;
        },
        setHideSidebar(state, payload) {
            state.hideSidebar = payload;
        },
    },
    actions: {
        setHideSidebar({commit}, payload) {
            commit("setHideSidebar", payload);
        },
        setNavs({commit}, payload) {
            commit("setNavs", payload);
        },
    },
    getters: {
        getNavs(state) {
            for (const key in state.navs) {
                if (router.currentRoute["_value"].name === state.navs[key].routeName) {
                    state.navs[key].active = true;
                } else {
                    state.navs[key].active = false;
                }

                if (state.navs[key].children) {
                    state.navs[key].children.forEach(childrenNav => {
                        if (router.currentRoute["_value"].name === childrenNav.routeName) {
                            childrenNav.active = true;
                        } else {
                            childrenNav.active = false;
                        }
                    });
                }
            }

            return state.navs;
        },
        getHideSidebar(state) {
            return state.hideSidebar;
        },
    },
};
