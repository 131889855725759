<template>
    <app-layout>
        <edit-view
            :model="model"
        />
    </app-layout>
</template>

<script>

import AppLayout from "../../../layouts/AppLayout.vue";
import EditView from "../../../components/views/edit/Index.vue";
import {mapGetters} from "vuex";

export default {
    name: "HeadingEdit",

    components: {
        EditView,
        AppLayout
    },

    computed: {
        ...mapGetters({
            model: 'headings/getModel',

        })
    },
};
</script>
