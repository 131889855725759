<template>
    <ul v-if="pagination.total > pagination.limit" class="inline-flex -space-x-px py-4">
        <li>
            <button
                @click="changePage(this.pagination.current_page - 1)"
                class="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
                :class="this.pagination.current_page === this.pagination.from ? 'pointer-events-none' : ''"
            >
                <fa-icon class="" icon="arrow-left"/>
            </button>
        </li>
        <li v-for="page in setLimit" :key="page">
            <button
                @click="changePage(page)"
                class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                :class="pagination.current_page === page ? 'bg-blue-500 text-white' : ''"
            >
                {{ page }}
            </button>
        </li>
        <li>
            <button
                @click="changePage(this.pagination.current_page + 1)"
                class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
                :class="this.pagination.current_page === this.pagination.last_page ? 'pointer-events-none' : ''"
            >
                <fa-icon class="" icon="arrow-right"/>
            </button>
        </li>
    </ul>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "Pagination",
    props: {
        model: {
            type: String,
            default() {
                return null;
            },
        },
    },
    data() {
      return {
          pages: 0,
          maxVisible: 5,
      }
    },
    computed: {
        ...mapState({
            pagination (state, getters) {
                return getters[`${this.model}/getPagination`]
            },
        }),
        setLimit() {
            function range(start, end) {
                return Array.from(Array(end - start + 1), (_, i) => i + start);
            }

            const max = this.maxVisible;
            const middle = Math.floor(this.maxVisible / 2);
            const pageNum = this.pages;

            if (pageNum < max) {
                return range(1, pageNum);
            }

            let start = this.pagination.current_page - middle;
            let end = this.pagination.current_page + middle;

            if (this.pagination.current_page >= pageNum - middle) {
                start = pageNum - max + 1;
                end = pageNum;
            }

            return range(Math.max(1, start), Math.max(end, max));
        }
    },
    created() {
        this.pages = Math.ceil(this.pagination.total / this.pagination.limit)
    },
    methods: {
        changePage(page) {
            this.$store.dispatch(`${this.model}/loadData`, {page})
        },
        // nextPage(page) {
        //     if (this.pagination.last_page !== this.pagination.currentPage) {
        //         this.$store.dispatch(`${this.model}/loadData`, {page})
        //     }
        // }
    },
}
</script>

<style scoped>

</style>