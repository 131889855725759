<template>
    <app-layout>
        <table-confirm-delete-modal
            :model="model"
            active
            :imageSrc="currentImageSrc"
        />
        <banner />
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <template v-for="image in data" :key="image.image_src">
                <video @click="openDeleteConfirmModal(image.id, image.image_src)" class="cursor-pointer transition-opacity duration-300 hover:opacity-80" muted v-if="image.image_src.includes('.mp4') || image.image_src.includes('.mov') || image.image_src.includes('.avi')">
                    <source :src="`${serverUrl}/storage/${image.image_src}`" type="video/mp4">
                </video>
                <img v-else class="cursor-pointer transition-opacity duration-300 hover:opacity-80" @click="openDeleteConfirmModal(image.id, image.image_src)"  :src="`${serverUrl}/storage/${image.image_src}`" alt="">
            </template>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "../../../layouts/AppLayout.vue";
import {mapGetters} from "vuex";
import Banner from "./modules/Banner";
import appConfigs from "../../../configs/app";
import TableConfirmDeleteModal from "../../../components/ui/table/modals/ConfirmDeleteModal";

export default {
    name: "PagesHomeIndex",
    components: {
        TableConfirmDeleteModal,
        Banner,
        AppLayout,
    },
    data() {
        return {
            serverUrl: appConfigs.server_url,
            data: null,
            currentImageSrc: ''
        }
    },
    computed: {
        ...mapGetters({
            model: 'pages-home/getModel',
        })
    },
    created() {
        this.getAll()
    },
    methods: {
        openDeleteConfirmModal(id, img) {
            this.currentImageSrc = img
            this.emitter.emit(`show::${this.model}ConfirmDeleteModal`, id);
        },
        getAll() {
            this.$axios.get("pages/home-page/get-all")
                .then(res => {
                    this.data = res.data
                })
        },
    }
}
</script>

<style scoped>

</style>