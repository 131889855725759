export default {
  state: () => ({
    navTitle: ''
  }),
  mutations: {
    setNavTitle(state, payload) {
      state.navTitle = payload;
    },
  },
  actions: {
    setNavTitle({ commit }, payload) {
      commit("setNavTitle", payload);
    },
  },
  getters: {
    getNavTitle(state) {
      return state.navTitle;
    },
  },
};
