<template>
    <div v-show="show">
        <div class="z-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 w-full md:inset-0 h-modal md:h-full justify-center items-center flex">
          <div class="relative p-4 w-full max-w-2xl h-full md:h-auto ">
            <div class="animate__animated animate__fadeInDown animate__faster relative bg-white rounded-lg z-50" >
              <div class="flex justify-between items-start p-4 ">
                <slot name="title"></slot>
                <button @click="hideModal" type="button" class="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm ml-auto absolute right-0 mr-2">
                  <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
              </div>
              <div class="p-4">
                <slot></slot>
              </div>
              <div class="flex items-center p-4">
                <slot name="actions"></slot>
              </div>
            </div>
          </div>
          <div @click="hideModal" class="animate__animated animate__fadeIn animate__faster absolute bg-black/50  top-0 left-0 right-0 w-full h-full z-40"></div>
        </div>
    </div>
<!--  <Transition name="slide-fade">-->
<!--    <div v-if="show">-->
<!--        <div class="z-50 fixed w-full max-w-2xl justify-center items-center flex">-->
<!--          <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">-->
<!--            <div class="relative bg-white rounded-lg ">-->
<!--              <div class="flex justify-between items-start p-4 ">-->
<!--                <slot name="title"></slot>-->
<!--                <button @click="hideModal" type="button" class="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm ml-auto absolute right-0 mr-2">-->
<!--                  <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>-->
<!--                </button>-->
<!--              </div>-->
<!--              <div class="p-4">-->
<!--                <slot></slot>-->
<!--              </div>-->
<!--              <div class="flex items-center p-4">-->
<!--                <slot name="actions"></slot>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      <div @click="hideModal" class="absolute bg-black opacity-25 top-0 left-0 right-0 w-full h-full z-10"></div>-->
<!--    </div>-->
<!--  </Transition>-->
</template>

<script>
export default {
  name: "Modal",
  props: {
    modalId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: false,
    }
  },

  created() {
    this.emitter.on(`modal::show::${this.modalId}`, this.showModal);
    this.emitter.on(`modal::hide::${this.modalId}`, this.hideModal);
  },

  beforeUnmount() {
    this.emitter.off(`modal::show::${this.modalId}`, this.showModal);
    this.emitter.off(`modal::hide::${this.modalId}`, this.hideModal);
  },

  methods: {
    showModal() {
      this.show = true
    },
    hideModal() {
      this.show = false
    },
  },

}
</script>

<style scoped>

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>