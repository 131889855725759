import UsersIndex from "@/views/users/Index";
import UsersCreate from "@/views/users/Create";
import UsersEdit from "@/views/users/Edit";
import UsersDetail from "@/views/users/Detail";

export default [
  {
    path: "/users",
    name: "Users",
    component: UsersIndex,
  },

  {
    path: "/users/create",
    name: "UsersCreate",
    component: UsersCreate,
  },

  {
    path: "/users/:id/show",
    name: "UsersShow",
    component: UsersDetail,
  },

  {
    path: "/users/:id/edit",
    name: "UsersEdit",
    component: UsersEdit,
  },
];