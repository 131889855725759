<template>
    <button
        type="button"
        :class="btnStyles"
        :disabled="disabled"
    >
        <template v-if="!loading">
            {{ text }}
        </template>
        <template v-if="loading">
            Loading...
        </template>
    </button>
</template>

<script>
export default {
    name: "Btn",

    props: {
        type: {
            type: String,
            default() {
                return 'primary'
            }
        },
        size: {
            type: String,
            default() {
                return 'base'
            }
        },
        text: {
            type: String,
            default() {
                return 'Button'
            }
        },
        color: {
            type: String,
            default() {
                return 'blue'
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false
            }
        },
    },

    computed: {
        btnStyles() {
            let style = `font-semibold rounded transition-colors focus:outline-none whitespace-nowrap`;

            if (this.color === 'blue') {
                if (this.type === 'primary') {
                    style += ` text-white bg-blue-400 border-2 border-transparent hover:bg-blue-600 focus:ring-4 focus:ring-blue-300`;
                }
                if (this.type === 'secondary') {
                    style += ` text-blue-400 bg-transparent border-2 border-blue-400 hover:bg-blue-400 hover:text-white focus:ring-4 focus:ring-blue-300`;
                }
                if (this.type === 'tertiary') {
                    style += ` text-blue-400 bg-transparent border-transparent hover:text-blue-600`;
                }
            }

            if (this.color === 'green') {
                if (this.type === 'primary') {
                    style += ` text-white bg-emerald-400 border-2 border-transparent hover:bg-emerald-600 focus:ring-4 focus:ring-emerald-300`;
                }
                if (this.type === 'secondary') {
                    style += ` text-emerald-400 bg-transparent border-2 border-emerald-400 hover:bg-emerald-400 hover:text-white focus:ring-4 focus:ring-emerald-300`;
                }
                if (this.type === 'tertiary') {
                    style += ` text-emerald-400 bg-transparent border-transparent hover:text-emerald-600`;
                }
            }

            if (this.color === 'red') {
                if (this.type === 'primary') {
                    style += ` text-white bg-red-400 border-2 border-transparent hover:bg-red-600 focus:ring-4 focus:ring-red-300`;
                }
                if (this.type === 'secondary') {
                    style += ` text-red-400 bg-transparent border-2 border-red-400 hover:bg-red-400 hover:text-white focus:ring-4 focus:ring-red-300`;
                }
                if (this.type === 'tertiary') {
                    style += ` text-red-400 bg-transparent border-transparent hover:text-red-600`;
                }
            }

            if (this.size === 'base') {
                style += ` text-base px-7 py-2`;
            } else if (this.size === 'sm') {
                style += ` text-sm px-5 py-1.5`;
            } else if (this.size === 'xs') {
                style += ` text-xs px-3 py-1`
            } else if (this.size === 'lg') {
                style += ` text-lg px-9 py-2.5`
            } else if (this.size === 'xl') {
                style += ` text-xl px-11 py-3`
            }

            if (this.loading) {
                style += ` cursor-not-allowed`;
            }

            return style;
        }
    },

    methods: {
        // btnStyles() {
        //     let style = `font-medium rounded transition-colors focus:outline-none whitespace-nowrap`;
        //
        //     if (this.color === 'primary') {
        //         style += ` text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300`;
        //     } else if (this.color === 'success') {
        //         style += ` text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300`;
        //     } else if (this.color === 'error') {
        //         style += ` text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300`
        //     } else if (this.color === 'yellow') {
        //         style += ` text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-300`
        //     } else if (this.color === 'purple') {
        //         style += ` text-white bg-purple-500 hover:bg-purple-600 focus:ring-4 focus:ring-purple-300`
        //     } else if (this.color === 'dark') {
        //         style += ` text-white bg-gray-400 hover:bg-gray-400 focus:ring-4 focus:ring-gray-300`
        //     }
        //
        //     if (this.size === 'base') {
        //         style += ` text-base px-7 py-2`;
        //     } else if (this.size === 'sm') {
        //         style += ` text-sm px-5 py-1.5`;
        //     } else if (this.size === 'xs') {
        //         style += ` text-xs px-3 py-1`
        //     } else if (this.size === 'lg') {
        //         style += ` text-lg px-9 py-2.5`
        //     } else if (this.size === 'xl') {
        //         style += ` text-xl px-11 py-3`
        //     }
        //
        //     // if (this.type === 'primary') {
        //     //     style += ` `;
        //     // } else if (this.type === 'secondary') {
        //     //     style += ` bg-transparent`;
        //     // } else if (this.type === 'tertiary') {
        //     //     style += ` border-transparent`
        //     // }
        //
        //     if (this.loading) {
        //         style += ` cursor-not-allowed`;
        //
        //     }
        //
        //     return style;
        // },
    },
}
</script>

<style scoped>

</style>