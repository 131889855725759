<template>
  <app-layout>
    <div class="flex justify-center">
      <pacman-loader
          :loading="loading"
          color="#3b82f6"
      />
    </div>

    <table-index
      :model="model"
      :heads="tableHeads"
      :items="items"
      :currentPage="currentPage"
      :total="total"
      :pages="pages"
      :perPage="perPage"
      @changePage="loadData"
    />
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import TableIndex from "@/components/ui/table/TableIndex";
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';

export default {
  name: "DealerGroupsIndex",

  components: {TableIndex, AppLayout, PacmanLoader},

  data() {
    return {
      model: 'dealer-groups',
      pageTitle: 'Dealer groups',
      loading: false,

      data: null,
      items: [],

      tableHeads: [
        {title: 'Name', key: 'name'},
      ],
      currentPage: 1,
      perPage: null,
      total: null,
      pages: null
    }
  },

  created() {
    this.loadData();

    this.$store.dispatch('setNavTitle', this.pageTitle);
  },


  methods: {
    loadData(page) {
      this.loading = true;

      this.$axios.get(`/${this.model}?page=${page}`)
        .then(res => {
          this.data = res.data;
          this.items = res.data.data;
          this.currentPage = res.data.current_page
          this.perPage = res.data.per_page
          this.total = res.data.total
          this.pages = Math.ceil(res.data.total / 20)
          this.loading = false;
        })
        .catch(e => {
          this.$toast.warning(e.data.message);
          this.loading = false;
        });
    },
  }
}
</script>

<style scoped>

</style>