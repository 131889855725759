<template>
        <table-index
            :loading="loading"
            :model="model"
            :heads="viewIndexData.tableHeads"
            :items="items"
            :items-limit="viewIndexData.tableProps.itemsLimit"
            :edit="viewIndexData.tableProps.edit"
            :create="viewIndexData.tableProps.create"
            :show="viewIndexData.tableProps.show"
            :destroy="viewIndexData.tableProps.destroy"
            :group="viewIndexData.tableProps.group"
            :download-excel="viewIndexData.tableProps.downloadExcel"
            :upload="viewIndexData.tableProps.upload"
            @inputSearch="inputSearch"
        />
</template>

<script>
    import TableIndex from "../../ui/table/TableIndex.vue";
    import {mapState} from "vuex";

    export default {
        name: "IndexView",

        components: {
            TableIndex,
        },

        props: {
            model: {
                type: String,
                default() {
                    return null;
                }
            },
        },

        data() {
            return {
                // loading: false,
                params: {
                    paginate: null,
                    keywords: null,
                },
            }
        },

        computed: {
            ...mapState({
                items (state, getters) {
                    return getters[`${this.model}/getItems`]
                },
                pagination (state, getters) {
                    return getters[`${this.model}/getPagination`]
                },
                viewIndexData (state, getters) {
                    return getters[`${this.model}/getViewIndexData`]
                },
                loading (state, getters) {
                    return getters[`${this.model}/getLoading`]
                }
            })
        },

        created() {
            this.loadData();
            this.$store.dispatch('setNavTitle', this.viewIndexData.pageTitle);
        },


        methods: {
            loadData() {
                // this.loading = true;

                this.params.paginate = this.pagination.limit ? this.pagination.limit : null;

                // this.$axios.get(`/${this.model}`, {
                //     params: this.params
                // })
                //     .then(res => {
                //         if (res && res.data && res.data.data) {
                //             this.$store.dispatch(`${this.model}/setPagination`, res.data)
                //             this.$store.dispatch(`${this.model}/setItems`, res.data.data)
                //         } else if (res && res.data) {
                //             this.$store.dispatch(`${this.model}/setItems`, res.data)
                //         }
                //
                //         this.loading = false;
                //     })
                //     .catch(e => {
                //         this.$toast.warning(e.data.message);
                //         this.loading = false;
                //     });

                this.$store.dispatch(`${this.model}/loadData`)
                    // .then(() => {
                    //     this.loading = false
                    // })
                    // .catch(() => {
                    //     this.loading = false
                    // })
                // this.loading = false
            },

            inputSearch(value) {
                // this.loading = true;
                this.$store.dispatch(`${this.model}/loadData`, {keywords: value})
                // this.loading = false
            }
        }
    }
</script>

<style scoped>

</style>