<template>
    <template v-if="loading">
        <div class="flex justify-center">
            <grid-loader
                color="#cbd5e1"
            />
        </div>
    </template>
    <template v-if="!loading">
        <template v-for="(field, key) in fields" :key="key">
            <component
                :options="field.options"
                :label="field.title"
                :type="field.type"
                :labelText="field.title"
                :is="`input-${field.type}`"
                :error-message="errors && errors[field.key] ? errors[field.key][0] : ''"
                v-model="field.value"
                :belongs-to="field.belongs_to"
                :has-many="field.has_many"
            />
        </template>

        <slot name="fields" :fields="fields"></slot>

        <btn
            text="Create"
            color="green"
            :loading="loading"
            @click="submit"
        />
    </template>
</template>

<script>
    import GridLoader from "vue-spinner/src/GridLoader.vue";
    import {mapState} from "vuex";
    import Btn from "../../ui/buttons/Btn.vue";

    export default {
        name: "CreateView",

        components: {
            Btn,
            GridLoader
        },

        props: {
            model: {
                type: String,
                default() {
                    return null;
                }
            },
        },

        data() {
            return {
                fields: {},
                errors: null,
                loading: false,
            };
        },

        computed: {
            ...mapState({
                viewCreateData (state, getters) {
                    return getters[`${this.model}/getViewCreateData`]
                },
            })
        },

        created() {
            this.loadData();

            this.$store.dispatch('setNavTitle', this.viewCreateData.pageTitle);
        },

        methods: {
            loadData() {
                this.loading = true;

                this.$axios.get(`/${this.model}/create`)
                    .then(res => {
                        this.fields = res.data;
                        this.loading = false;
                    })
                    .catch(e => {
                        this.$toast.warning(e.data.message);
                        this.loading = false;
                    });
            },

            submit() {
                this.loading = true;
                let formData = new FormData;

                for (let key in this.fields) {
                    if (this.fields[key].value) {
                        formData.set(this.fields[key].key, this.fields[key].value);
                    }
                }

                this.$axios.post(`/${this.model}/store`, formData)
                    .then(() => {
                        this.$router.push(`/${this.model}`);
                        this.loading = false;
                    })
                    .catch(e => {
                        this.$toast.warning(e.data.message);

                        if (e.status === 422) {
                            this.errors = e.data.errors;
                        }

                        this.loading = false;
                    });
            }
        }
    }
</script>

<style scoped>

</style>