<template>
  <app-layout>
    <pacman-loader
        v-if="loading"
        :loading="loading"
        color="#3b82f6"
    />

    <template
        v-if="!loading"
    >
      <template v-for="(field, key) in fields" :key="key">
        <div class="mb-6">
          <h1 class="font-medium text-sm text-slate-400">{{ field.text }}</h1>
          <p class="text-xl">{{ data[field.key] }}</p>
        </div>
      </template>
    </template>

      <div class="mb-4">
          <h1 class="font-medium text-sm text-slate-400">Registered users</h1>

          <table-index
              :model="userModel"
              :heads="userTableHeads"
              :items="data.users"
              :create="false"
              :edit="false"
              :destroy="false"
          />
      </div>

  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';
import TableIndex from "@/components/ui/table/TableIndex";

export default {
  name: "AffiliateLinksDetail",

  components: {
    AppLayout,
    PacmanLoader,
      TableIndex,
  },

    data() {
        return {
            model: 'affiliate-links',
            pageTitle: 'Affiliate link details',
            loading: true,
            data: {},
            fields: [
                {text: 'Link', key: 'registration_link'},
                {text: 'Code', key: 'code'},
            ],

            userModel: 'users',
            userTableHeads: [
                {title: 'First name', key: 'first_name'},
                {title: 'Last name', key: 'last_name'},
                {title: 'Email', key: 'email'},
            ],
        }
    },

  created() {
    this.loadData();

    this.$store.dispatch('setNavTitle', this.pageTitle);
  },

  methods: {
    loadData() {
      this.loading = true;
      this.$axios
          .get(`/${this.model}/${this.$route.params.id}/show`)
          .then((response) => {
            this.data = response.data;
            this.loading = false;
          })
          .catch(e => {
            this.$toast.warning(e.data.message);
            this.loading = false;
          });
    },
  },
};
</script>
