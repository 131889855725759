<template>
  <div class="mb-6 w-full sm:w-1/2 md:w-full max-w-3xl">
    <label v-if="label" :class="labelStyles()">{{ label }}</label>
    <textarea
        rows="4"
        :class="inputStyles()"
        placeholder="Leave a comment..."
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "InputTextarea",
  props: {
    areaResize: {
      type: Boolean,
      default() {
        return false
      }
    },
    label: {
      type: String,
      default() {
        return ''
      }
    },
    modelValue: {
      type: String,
      default: null,
    }
  },
  methods: {
    inputStyles() {
      let resize = `bg-slate-100 border-slate-200 text-black outline-blue-300 focus:bg-white block w-full rounded border
        focus:outline-offset-3 focus:outline-4 text-base p-2`
      if (this.areaResize) {
        resize += ` resize-none`
      }
      return resize
    },
    labelStyles() {
      let labelStyle = `block mb-2 text-sm `
      if (this.errorMessage) {
        labelStyle += ` text-red-600 `
      }
      return labelStyle
    }
  }
}
</script>

<style scoped>

</style>