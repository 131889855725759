import Toast, { POSITION }  from "vue-toastification";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";


const options = {
  position: POSITION.BOTTOM_LEFT,
};

export default {
  install: (app) => {
    app.use(Toast, options);

    app.config.globalProperties.$toast = useToast();
  }
}