import * as getters from './getters.js';
import * as mutations from './mutations.js';
import * as actions from './actions.js';

const state = {
    model: 'continents',
    items: [],

    constants: {},

    pagination: {
        limit: 20,
    },

    loading: false,

    viewIndexData: {
        pageTitle: 'Continents',
        tableHeads: [
            {title: 'Name', key: 'name'},
            {title: 'Japanese name', key: 'name_jp'},

        ],
        tableProps: {
            create: true,
            show: true,
            destroy: true,
            edit: true,
            group: false,
            search: true,
            itemsLimit: true
        }
    },

    viewCreateData: {
        pageTitle: 'Create continent',
    },

    viewDetailData: {
        pageTitle: 'Continent details',
        fields: [
            {text: 'Name', key: 'name'},
            {title: 'Japanese name', key: 'name_jp'},

        ],
    },

    viewEditData: {
        pageTitle: 'Edit continent',
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};