import {createStore} from "vuex";
import {axiosInstance as axios} from "@/plugins/axios";

import SidebarStore from "../components/sidebar/store";
import NavbarStore from "../components/navbar/store";

import CouponsStore from "../views/coupons/store/state"
import UsersStore from "../views/users/store/state"
import OrdersStore from "../views/orders/store/state"
import ContinentsStore from '../views/continents/store/state'
import CountriesStore from '../views/countries/store/state'
import CountryPackagesStore from "../views/country-packages/store/state"
import AffiliatesStore from "../views/affiliates/store/state"
import ChargesStore from "../views/charges/store/state"
import PagesHomeStore from "../views/pages/pages-home/store/state"
import NavbarAnnouncementStore from "../views/pages/navbar/store/state"
import HeadingsStore from "../views/pages/headings/store/state"
import AboutStore from "../views/pages/about/store/state"
import ReportsStore from "../views/reports/store/state"
import GiftCardsStore from "../views/gift-cards/store/state"

import router from "@/router";

export default createStore({
    state: {
        user: {},
    },

    mutations: {
        setUser(state, user) {
            state.user = user;
        },

        logout(state) {
            state.user = null;
            localStorage.removeItem("access-token");
        },
    },

    actions: {
        loadMe({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/me")
                    .then(({data}) => {
                        commit("setUser", data.user);
                        commit("setNavs", data.navs);

                        resolve(data);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },

        logout(state) {
            if (state.user || localStorage.getItem("access-token")) {
                return new Promise((resolve, reject) => {
                    axios
                        .get("/logout")
                        .then(({data}) => {
                            state.commit("logout");
                            router.push({name: "Login"})
                            resolve(data);
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            }
        },
        setUser({commit}, payload) {
            commit('setUser', payload);
        }
    },

    getters: {
        getUser: (state) => {
            return state.user;
        },
    },

    modules: {
        NavbarStore,
        SidebarStore,
        coupons: CouponsStore,
        users: UsersStore,
        orders: OrdersStore,
        continents: ContinentsStore,
        countries: CountriesStore,
        affiliates: AffiliatesStore,
        'country-packages': CountryPackagesStore,
        charges: ChargesStore,
        reports: ReportsStore,
        'pages-home': PagesHomeStore,
        'navbar-announcement': NavbarAnnouncementStore,
        'gift-cards': GiftCardsStore,
        headings: HeadingsStore,
        about: AboutStore
    },
});
