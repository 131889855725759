<template>
    <div class="mb-6 w-full sm:w-1/2 md:w-full max-w-3xl">
        <label v-if="label" :class="labelStyles()">{{ label }}</label>

        <v-select
            label="name"
            :class="inputStyles()"
            :reduce="option => option.value"
            :options="options"
            :placeholder="placeholder"
            :disabled="disabled"
            v-model="value"
            @search:blur="blur"
            @option:selected="selected"
        />
        <p class="mt-2 text-sm text-red-600" v-if="errorMessage">
            {{ errorMessage }}
        </p>
    </div>
</template>

<script>
export default {
    name: "InputSelect",

    props: {
        size: {
            type: String,
            default() {
                return "base";
            },
        },
        errorMessage: {
            type: String,
            default() {
                return "";
            },
        },
        label: {
            type: String,
            default() {
                return "";
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
        modelValue: {
            default() {
                return '';
            },
        },
        placeholder: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default() {
                return [];
            },
        }
    },

    data() {
        return {
            value: '',
        }
    },

    created() {
        this.initInput();
    },

    methods: {
        initInput() {
            let findOption = this.options.find(option => option.value === this.modelValue);

            if (findOption) {
                this.value = findOption.name;
            }
        },

        selected(option) {
            this.$emit('update:modelValue', option.value);
            this.$emit('selected', option);
        },

        inputStyles() {
            let style = `input-select block w-full rounded border
        focus:outline-offset-3 focus:outline-4`;

            if (this.size === 'base') {
                style += ` text-base p-2`;
            }

            if (this.size === 'sm') {
                style += ` text-sm p-2`;
            }

            if (this.size === 'lg') {
                style += ` text-lg p-2`
            }

            if (this.errorMessage) {
                style += ` bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 outline-red-500`
            } else {
                style += ` bg-slate-100 border-slate-200 text-black outline-blue-300 focus:bg-white`
            }

            return style;
        },

        labelStyles() {
            let labelStyle = `block mb-2 text-sm `;
            if (this.errorMessage) {
                labelStyle += ` text-red-600 `;
            }
            return labelStyle;
        },

        blur() {
            this.$emit('blur');
        }
    },
}
</script>

<style lang="scss">
.input-select .vs__dropdown-toggle {
    padding: 0;
    border: none;
}

.input-select .vs__selected-options {
    padding: 0;
}

.input-select .vs__selected {
    padding: 0;
    margin: 0;
}

.input-select .vs__search, .input-select .vs__search:focus {
    padding: 0;
    margin: 0;
    line-height: inherit;
}

.input-select .vs__dropdown-menu {
    padding: 0;
}

.input-select .vs__actions {
    padding: 0;
}
</style>