<template>
    <app-layout>
        <pacman-loader
            v-if="loading"
            :loading="loading"
            color="#3b82f6"
        />

        <template
            v-if="!loading"
        >
            <template v-for="(field, key) in fields" :key="key">
                <div class="mb-4">
                    <h1 class="font-medium text-sm text-slate-400">{{ field.text }}</h1>
                    <template v-if="!field.belongs_to">
                        <p class="text-xl">{{ data[field.key] }}</p>
                    </template>
                    <template v-if="field.belongs_to">
                        <p class="text-xl">
                            {{ data[field.belongs_to] ? data[field.belongs_to][field.key] : 'Not set' }}
                        </p>
                    </template>
                </div>
            </template>

            <div class="mb-4">
                <h1 class="font-medium text-sm text-slate-400">Package plans</h1>

                <table-index
                    :model="countryPackageModel"
                    :heads="countryPackageTableHeads"
                    :items="data.packages"
                    :create="false"
                    :edit="false"
                    :destroy="false"
                />
            </div>
        </template>

    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';
import TableIndex from "@/components/ui/table/TableIndex";

export default {
    name: "CountriesDetail",

    components: {
        TableIndex,
        AppLayout,
        PacmanLoader,
    },

    data() {
        return {
            model: 'countries',
            pageTitle: 'Country details',
            loading: true,
            data: {},
            fields: [
                {text: 'Name', key: 'name'},
                {text: 'Continent', key: 'name', belongs_to: 'continent'},
                {text: 'Price for 1 to 10 days', key: 'price_for_1_to_10_days'},
                {text: 'Price for 11 to 20 days', key: 'price_for_11_to_20_days'},
                {text: 'Price for 21 and more', key: 'price_for_21_to_30_days'},
            ],

            countryPackageModel: 'country-packages',
            countryPackageTableHeads: [
                {title: 'Name', key: 'name'},
                {title: 'Price', key: 'price'},
            ],
        }
    },

    created() {
        this.loadData();

        this.$store.dispatch('setNavTitle', this.pageTitle);
    },

    methods: {
        loadData() {
            this.loading = true;
            this.$axios
                .get(`/${this.model}/${this.$route.params.id}/show`)
                .then((response) => {
                    this.data = response.data;
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                    this.loading = false;
                });
        },
    },
};
</script>
