import DealersIndex from "@/views/dealers/Index";
import DealersCreate from "@/views/dealers/Create";
import DealersEdit from "@/views/dealers/Edit";
import DealersDetail from "@/views/dealers/Detail";

export default [
  {
    path: "/dealers",
    name: "Dealers",
    component: DealersIndex,
  },

  {
    path: "/dealers/create",
    name: "DealersCreate",
    component: DealersCreate,
  },

  {
    path: "/dealers/:id/show",
    name: "DealersShow",
    component: DealersDetail,
  },

  {
    path: "/dealers/:id/edit",
    name: "DealersEdit",
    component: DealersEdit,
  },
];