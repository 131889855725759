<template>
    <app-layout>
        <index-view
            :model="model"
        />
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import IndexView from "@/components/views/index/Index.vue";
import {mapGetters} from "vuex";

export default {
    name: "HeadingIndex",

    components: {IndexView, AppLayout},
    computed: {
        ...mapGetters({
            model: 'headings/getModel',
        })
    }
}
</script>

<style scoped>

</style>