<template>
    <template v-if="loading">
        <pacman-loader />
    </template>

    <template v-if="!loading">
        <router-view />
    </template>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      loading: true,
    };
  },

  created() {
    this.$store
      .dispatch("loadMe")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  methods: {},
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
</style>
