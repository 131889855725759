<template>
    <div :class="sidebarStyles()">
        <div>
            <sidebar-brand/>

            <sidebar-navs/>
        </div>

        <div>
            <div
                class="flex items-center cursor-pointer mb-2 rounded text-gray-600 hover:bg-slate-100 hover:text-blue-500"
                @click="logout"
            >
                <div :class="sidebarNavItemIconStyles()">
                    <fa-icon class="" icon="arrow-right-from-bracket"/>
                </div>
                <div class="text-base" v-if="!hideSidebar">
                    Exit
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import SidebarBrand from "./SidebarBrand.vue";
import SidebarNavs from "./SidebarNavs.vue";

export default {
    name: "SidebarIndex",
    components: {
        SidebarNavs,
        SidebarBrand

    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            hideSidebar: "getHideSidebar",
        }),
    },

    created() {
        if (window.screen.width <= 640) {
            this.$store.dispatch('setHideSidebar', true);
        }
    },

    methods: {
        sidebarStyles() {
            let styles = `sidebar overflow-hidden z-10 bg-white h-screen fixed transition-all ease-in-out flex-col justify-between flex`;

            if (this.hideSidebar) {
                styles += " w-0 sm:w-16 p-0 sm:py-6 sm:px-3";
            } else {
                styles += " w-80 p-6";
            }

            return styles;
        },
        sidebarNavItemIconStyles() {
            let styles = "flex justify-center items-center text-lg w-10 h-10";

            if (this.hideSidebar) {
                styles += " mr-0";
            } else {
                styles += " mr-4";
            }

            return styles;
        },

        logout() {
            this.$store.dispatch("logout");
        }
    }
};
</script>

<style lang="scss"></style>
