import NavbarAnnouncementIndex from "@/views/pages/navbar/Index";
import NavbarAnnouncementCreate from "@/views/pages/navbar/Create";
import NavbarAnnouncementEdit from "@/views/pages/navbar/Edit";
import NavbarAnnouncementDetail from "@/views/pages/navbar/Detail";

export default [
  {
    path: "/navbar-announcement",
    name: "NavbarAnnouncement",
    component: NavbarAnnouncementIndex,
  },

  {
    path: "/navbar-announcement/create",
    name: "NavbarAnnouncementCreate",
    component: NavbarAnnouncementCreate,
  },

  {
    path: "/navbar-announcement/:id/show",
    name: "NavbarAnnouncementShow",
    component: NavbarAnnouncementDetail,
  },

  {
    path: "/navbar-announcement/:id/edit",
    name: "NavbarAnnouncementEdit",
    component: NavbarAnnouncementEdit,
  },
];