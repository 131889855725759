<template>
    <app-layout>
        <detail-view
            :model="model"
        />
    </app-layout>
</template>

<script>
import AppLayout from "../../../layouts/AppLayout.vue";
import DetailView from "../../../components/views/detail/Index.vue";
import {mapGetters} from "vuex";

export default {
    name: "HeadingDetail",

    components: {
        DetailView,
        AppLayout,
    },

    computed: {
        ...mapGetters({
            model: 'headings/getModel',

        })
    },
};
</script>
