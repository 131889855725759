<template>
  <div class="mb-6 w-full max-w-3xl">
    <label v-if="label" :class="labelStyles()">{{ label }}</label>
    <input
        type="password"
        placeholder="Password"
        :disabled="disable"
        :class="inputStyles()"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    >
    <p class="mt-2 text-sm text-red-600 " v-if="errorMessage">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: "InputPassword",
  props: {
    size: {
      type: String,
      default() {
        return 'base'
      }
    },
    errorMessage: {
      type: String,
      default() {
        return ''
      }
    },
    label: {
      type: String,
      default() {
        return ''
      }
    },
    disable: {
      type: Boolean,
      default() {
        return false
      }
    },
    modelValue: {
      type: String,
      default: null,
    }
  },
  methods: {
    inputStyles() {
      let styles = `block w-full rounded border
        focus:outline-offset-3 focus:outline-4`;
      if (this.size === 'base') {
        styles += ` text-base p-2`
      }
      if(this.size === 'sm') {
        styles += ` text-sm p-2`;
      }
      if(this.size === 'lg') {
        styles += ` text-lg p-2`
      }
      if(this.errorMessage) {
        styles += ` bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 outline-red-500`
      } else {
        styles += ` bg-slate-100 border-slate-200 text-black outline-blue-300 focus:bg-white`
      }
      return styles
    },
    labelStyles() {
      let labelStyle = `block mb-2 text-sm `
      if (this.errorMessage) {
        labelStyle += ` text-red-600 `
      }
      return labelStyle
    }
  }
}
</script>

<style scoped>

</style>