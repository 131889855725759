<template>
    <ul class="sidebar__navs">
        <template v-for="(nav, key) in navs" :key="`nav-${key}`">
            <li
                :class="sidebarNavItemStyles(nav)"
                @click.prevent="routeTo(nav)"
            >
                <template v-if="nav.icon">
                    <div :class="sidebarNavItemIconStyles()">
                        <fa-icon class="" :icon="nav.icon"/>
                    </div>
                </template>

                <div v-if="!hideSidebar" class="text-base">
                    {{ nav.label }}
                </div>

                <template v-if="nav.children">
                    <div class="text-base flex items-center ml-2 pb-1">
                        <fa-icon class="" icon="sort-down"/>
                    </div>
                </template>

                <template v-if="nav.active && nav.children">
                    <ul class="w-full">
                        <template v-for="(item, key) in nav.children" :key="`nav-children-${key}`">
                            <li
                                :class="sidebarNavItemStyles(item)"
                                @click.prevent="routeTo(item)"
                            >
                                <template v-if="item.icon">
                                    <div :class="sidebarNavItemIconStyles() + ' pl-8'">
                                        <fa-icon class="" :icon="item.icon"/>
                                    </div>
                                </template>

                                <div v-if="!hideSidebar" class="text-base pl-3">
                                    {{ item.label }}
                                </div>
                            </li>
                        </template>
                    </ul>
                </template>
            </li>
        </template>
    </ul>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "SidebarNavs",

    data() {
        return {};
    },

    computed: {
        ...mapGetters({
            navs: "getNavs",
            hideSidebar: "getHideSidebar",
        }),
    },

    methods: {
        sidebarNavItemStyles(nav) {
            let styles =
                "flex items-center flex-wrap cursor-pointer mb-2 rounded text-gray-600 hover:bg-slate-100 hover:text-blue-500";

            if (nav.active) {
                styles += " bg-slate-100 text-blue-500";
            }

            return styles;
        },

        sidebarNavItemIconStyles() {
            let styles = "flex justify-center items-center text-lg w-10 h-10";

            if (this.hideSidebar) {
                styles += " mr-0";
            } else {
                styles += " mr-4";
            }

            return styles;
        },

        routeTo(nav) {
            nav.active = true;

            if (nav.path) {
                this.$router.push(nav.path)
            }
        }
    },
};
</script>

<style scoped></style>
