<template>
  <auth-layout>
    <div class="container mx-auto max-w-lg rounded bg-white shadow-sm p-4">
      <h1 class="mb-4">Login</h1>

      <input-email
        label="Email"
        :error-message="errors && errors.email ? errors.email[0] : ''"
        v-model="form.email"
      />

      <input-password
        label="Password"
        :error-message="errors && errors.password ? errors.password[0] : ''"
        v-model="form.password"
      />

      <btn
        text="Login"
        @click="submit"
        :loading="loading"
      />
    </div>
  </auth-layout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout";
import InputEmail from "@/components/ui/inputs/InputEmail";
import InputPassword from "@/components/ui/inputs/InputPassword";
import Btn from "@/components/ui/buttons/Btn";

export default {
  name: "Login",

  components: {Btn, InputPassword, InputEmail, AuthLayout},

  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      errors: null,
      loading: false,
    }
  },

  methods: {
    submit() {
      this.loading = true
      this.$axios.post(`/login`, this.form)
        .then(res => {
          if (res.data.token) {
            this.$store.dispatch('setUser', res.data.user);
            localStorage.setItem("access-token", res.data.token);
            this.$router.push({name: "Home"});
            this.$store.dispatch("loadMe");
          }
          this.loading = false;
        })
        .catch(e => {
          this.$toast.warning(e.data.message);
          if (e.status === 422) {
            this.errors = e.data.errors;
          }
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>

</style>