<template>
    <app-layout>
        <select-dealer-group-modal
            :model="model"
        />

        <div class="flex justify-center">
            <pacman-loader
                :loading="loading"
                color="#3b82f6"
            />
        </div>

        <template v-if="!loading">
            <table-index
                :model="model"
                :heads="tableHeads"
                :items="items"
                :currentPage="currentPage"
                :total="total"
                :pages="pages"
                :perPage="perPage"
                @changePage="loadData"
                :group="true"
            />
        </template>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import TableIndex from "@/components/ui/table/TableIndex";
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';
import SelectDealerGroupModal from "@/views/dealers/modals/SelectDealerGroupModal";

export default {
    name: "DealersIndex",

    components: {SelectDealerGroupModal, TableIndex, AppLayout, PacmanLoader},

    data() {
        return {
            model: 'dealers',
            pageTitle: 'Dealers',
            loading: false,

            data: null,
            items: [],

            tableHeads: [
                {title: 'Name', key: 'name'},
                {title: 'City', key: 'name', belongs_to: 'city'},
                {title: 'Group', key: 'name', belongs_to: 'group'},
            ],
            currentPage: 1,
            perPage: null,
            total: null,
            pages: null
        }
    },

    created() {
        this.emitter.on(`${this.model}::group`, this.groupDealers);

        this.loadData();
        this.$store.dispatch('setNavTitle', this.pageTitle);
    },


    methods: {
        loadData(page) {
            this.loading = true;

            this.$axios.get(`/${this.model}?page=${page}`)
                .then(res => {
                    this.data = res.data;
                    this.items = res.data.data;
                    this.currentPage = res.data.current_page
                    this.perPage = res.data.per_page
                    this.total = res.data.total
                    this.pages = Math.ceil(res.data.total / 20)
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                    this.loading = false;
                });
        },

        groupDealers(data) {
            this.loading = true;
            let formData = new FormData;
            let dealerIds = [];
            this.items.forEach(item => {
                if (item.checked) {
                    dealerIds.push(item.id);
                }
            });

            formData.set('name', data.dealer_group.name);
            formData.set('dealer_ids', JSON.stringify(dealerIds));

            this.$axios.post(`/dealer-groups/${data.dealer_group.value}/update`, formData)
                .then(() => {
                    this.loadData();
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);

                    if (e.status === 422) {
                        this.errors = e.data.errors;
                    }

                    this.loading = false;
                });
        }
    }
}
</script>

<style scoped>

</style>