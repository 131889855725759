<template>
    <td
        v-if="head && item"
        class="px-6 py-4"
    >
        {{ item[head.belongs_to] ? item[head.belongs_to][head.key] : 'Not set' }}
    </td>
</template>

<script>
export default {
    name: "BelongsToColumn",

    props: {
        head: {
            type: Object,
            default() {
                return null;
            }
        },
        item: {
            type: Object,
            default() {
                return null;
            }
        },
    }
}
</script>

<style scoped>

</style>