import OrdersIndex from "@/views/orders/Index";
// import OrdersCreate from "@/views/orders/Create";
// import OrdersEdit from "@/views/orders/Edit";
import OrdersDetail from "@/views/orders/Detail";

export default [
  {
    path: "/orders",
    name: "Orders",
    component: OrdersIndex,
  },

  // {
  //   path: "/orders/create",
  //   name: "OrdersCreate",
  //   component: OrdersCreate,
  // },

  {
    path: "/orders/:id/show",
    name: "OrdersShow",
    component: OrdersDetail,
  },

  // {
  //   path: "/countries/:id/edit",
  //   name: "OrdersEdit",
  //   component: OrdersEdit,
  // },
];