import CouponsIndex from "@/views/coupons/Index";
import CouponsCreate from "@/views/coupons/Create";
import CouponsEdit from "@/views/coupons/Edit";
import CouponsDetail from "@/views/coupons/Detail";

export default [
  {
    path: "/coupons",
    name: "Coupons",
    component: CouponsIndex,
  },

  {
    path: "/coupons/create",
    name: "CouponsCreate",
    component: CouponsCreate,
  },

  {
    path: "/coupons/:id/show",
    name: "CouponsShow",
    component: CouponsDetail,
  },

  {
    path: "/coupons/:id/edit",
    name: "CouponsEdit",
    component: CouponsEdit,
  },
];