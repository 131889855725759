import CountryPackagesIndex from "@/views/country-packages/Index";
import CountryPackagesCreate from "@/views/country-packages/Create";
import CountryPackagesEdit from "@/views/country-packages/Edit";
import CountryPackagesDetail from "@/views/country-packages/Detail";

export default [
    {
        path: "/country-packages",
        name: "CountryPackages",
        component: CountryPackagesIndex,
    },

    {
        path: "/country-packages/create",
        name: "CountryPackagesCreate",
        component: CountryPackagesCreate,
    },

    {
        path: "/country-packages/:id/show",
        name: "CountryPackagesShow",
        component: CountryPackagesDetail,
    },

    {
        path: "/country-packages/:id/edit",
        name: "CountryPackagesEdit",
        component: CountryPackagesEdit,
    },
];