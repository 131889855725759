import AffiliateLinkIndex from "@/views/affiliate-links/Index";
import AffiliateLinkCreate from "@/views/affiliate-links/Create";
import AffiliateLinkDetail from "@/views/affiliate-links/Detail";

export default [
  {
    path: "/affiliate-links",
    name: "AffiliateLinks",
    component: AffiliateLinkIndex,
  },

  {
    path: "/affiliate-links/create",
    name: "AffiliateLinksCreate",
    component: AffiliateLinkCreate,
  },

  {
    path: "/affiliate-links/:id/show",
    name: "AffiliateLinksShow",
    component: AffiliateLinkDetail,
  },
];