export const getModel = (state) => {
    return state.model;
};

export const getConstants = (state) => {
    return state.constants;
};

export const getItems = (state) => {
    return state.items;
};

export const getPagination = (state) => {
    return state.pagination;
};

export const getViewIndexData = (state) => {
    return state.viewIndexData;
};

export const getViewChargesData = (state) => {
    return state.viewChargesData;
};

export const getViewCreateData = (state) => {
    return state.viewCreateData;
};

export const getViewDetailData = (state) => {
    return state.viewDetailData;
};

export const getViewEditData = (state) => {
    return state.viewEditData;
};

export const getLoading = (state) => {
    return state.loading;
};