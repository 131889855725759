<template>
    <app-layout>
        <template v-if="loading">
            <div class="flex justify-center">
                <pacman-loader
                    :loading="loading"
                    color="#3b82f6"
                />
            </div>
        </template>

        <template v-if="!loading">
            <btn
                text="Generate new"
                size="sm"
                :loading="loading"
                @click.prevent="generateNew"
            />

            <table-index
                :model="model"
                :heads="tableHeads"
                :items="items"
                :create="false"
                :edit="false"
            />
        </template>
        </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import TableIndex from "@/components/ui/table/TableIndex";
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';
import {mapGetters} from "vuex";
import Btn from "@/components/ui/buttons/Btn";

export default {
  name: "AffiliateLinksIndex",

  components: {Btn, TableIndex, AppLayout, PacmanLoader},

  data() {
    return {
      model: 'affiliate-links',
      pageTitle: 'Affiliate links',
      loading: false,

      data: null,
      items: [],

      tableHeads: [
          {title: 'Link', key: 'registration_link'},
        {title: 'Code', key: 'code'},
      ],
    }
  },

    computed: {
        ...mapGetters({
            user: 'getUser',
        })
    },

  created() {
    this.loadData();

    this.$store.dispatch('setNavTitle', this.pageTitle);
  },

  methods: {
    loadData() {
      this.loading = true;

      this.$axios.get(`/affiliates/${this.user.id}/links`)
        .then(res => {
          this.data = res.data;
          this.items = res.data.data;
          this.loading = false;
        })
        .catch(e => {
          this.$toast.warning(e.data.message);
          this.loading = false;
        });
    },

      generateNew() {
          this.loading = true;

          this.$axios.post(`/affiliate-links/generate`, {
              'affiliate_id': this.user.id
          })
              .then(() => {
                  this.loading = false;
                  this.loadData();
              })
              .catch(e => {
                  this.$toast.warning(e.data.message);
                  this.loading = false;
              });
      }
  }
}
</script>

<style scoped>

</style>