import * as getters from './getters.js';
import * as mutations from './mutations.js';
import * as actions from './actions.js';

const state = {
    model: 'country-packages',
    items: [],

    constants: {},

    pagination: {
        limit: 25,
    },

    loading: false,

    viewIndexData: {
        pageTitle: 'Country packages',
        tableHeads: [
            {title: 'Country', key: 'name', belongs_to: 'country'},
            {title: 'Name', key: 'name'},
            {title: 'Price', key: 'price'},
            {title: 'Offering Code', key: 'offering_code'},
            {title: 'Offering ID', key: 'offering_id'},
        ],
        tableProps: {
            create: true,
            show: true,
            destroy: true,
            edit: true,
            group: false,
            search: true,
            itemsLimit: true,
            upload: true,
        }
    },

    viewCreateData: {
        pageTitle: 'Create country package',
    },

    viewDetailData: {
        pageTitle: 'Country package details',
        fields: [
            {text: 'Country', key: 'name', belongs_to: 'country'},
            {text: 'Name', key: 'name'},
            {text: 'Price', key: 'price'},
            {text: 'Note', key: 'note'},
            {text: 'Index', key: 'index'},
            {text: 'Offering Code', key: 'offering_code'},
            {text: 'Offering ID', key: 'offering_id'},
        ],
    },

    viewEditData: {
        pageTitle: 'Edit Country package',
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};