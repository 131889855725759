<template>
  <app-layout>
    <pacman-loader
        v-if="loading"
        :loading="loading"
        color="#3b82f6"
    />

    <template
        v-if="!loading"
    >
      <template v-for="(field, key) in fields" :key="key">
        <div class="mb-4">
          <h1 class="font-medium text-sm text-slate-400">{{ field.text }}</h1>
          <template v-if="!field.belongs_to">
            <p class="text-xl">{{ data[field.key] }}</p>
          </template>
          <template v-if="field.belongs_to">
            <p class="text-xl">{{ data[field.belongs_to][field.key] }}</p>
          </template>
        </div>
      </template>
    </template>

  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';

export default {
  name: "DevicesDetail",

  components: {
    AppLayout,
    PacmanLoader,
  },

  data() {
    return {
      model: 'devices',
      pageTitle: 'Device details',
      loading: true,
      data: {},
      fields: [
        {text: 'Serial number', key: 'serial_number'},
        {text: 'Dealer', key: 'name', belongs_to: 'dealer'},
      ],
    }
  },

  created() {
    this.loadData();

    this.$store.dispatch('setNavTitle', this.pageTitle);
  },

  methods: {
    loadData() {
      this.loading = true;
      this.$axios
          .get(`/${this.model}/${this.$route.params.id}/show`)
          .then((response) => {
            this.data = response.data;
            this.loading = false;
          })
          .catch(e => {
            this.$toast.warning(e.data.message);
            this.loading = false;
          });
    },
  },
};
</script>
