<template>
    <app-layout>
        <detail-view
            class="mb-4"
            :model="model"
        />
        <div
            class="shadow-sm bg-white rounded-lg py-4 px-6 w-full md:w-1/2 mt-6"
        >
            <p class="mb-2">Charges history</p>
            <ChargesView />
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "../../layouts/AppLayout.vue";
import DetailView from "../../components/views/detail/Index.vue";
import ChargesView from "@/views/charges/Index.vue";

import {mapGetters} from "vuex";

export default {
    name: "UsersDetail",

    components: {
        DetailView,
        AppLayout,
        ChargesView
    },

    computed: {
        ...mapGetters({
            model: 'users/getModel',
        })
    },
};
</script>
