<template>
    <app-layout>
        <upload-countries-with-packages-modal
            :model="model"
        />

        <pacman-loader
            v-if="loading"
            :loading="loading"
            color="#3b82f6"
        />

        <template
            v-if="!loading"
        >

            <div class="mb-4 flex justify-between">
                <div></div>
                <div class="flex">
                    <btn
                        size="sm"
                        text="Upload countries with packages"
                        @click="openUploadCountriesAndPackagesModal"
                    />
                </div>
            </div>

            <template v-for="(field, key) in fields" :key="key">
                <div class="mb-4">
                    <h1 class="font-medium text-sm text-slate-400">{{ field.text }}</h1>
                    <p class="text-xl">{{ data[field.key] }}</p>
                </div>
            </template>

            <div class="mb-4">
                <h1 class="font-medium text-sm text-slate-400">Countries</h1>

                <table-index
                    :model="countryModel"
                    :heads="countryTableHeads"
                    :items="data.countries"
                />
            </div>
        </template>

    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';
import Btn from "@/components/ui/buttons/Btn";
import UploadCountriesWithPackagesModal from "@/views/continents/modals/UploadCountriesWithPackagesModal";
import TableIndex from "@/components/ui/table/TableIndex";

export default {
    name: "ContinentsDetail",

    components: {
        TableIndex,
        UploadCountriesWithPackagesModal,
        Btn,
        AppLayout,
        PacmanLoader,
    },

    data() {
        return {
            model: 'continents',
            pageTitle: 'Continent details',
            loading: true,
            data: {},
            fields: [
                {text: 'Name', key: 'name'},
            ],

            countryModel: 'countries',
            countryTableHeads: [
                {title: 'Name', key: 'name'},
            ],
        }
    },

    created() {
        this.emitter.on(`show.load-data::${this.model}`, this.loadData);
        this.loadData();

        this.$store.dispatch('setNavTitle', this.pageTitle);
    },

    beforeUnmount() {
        this.emitter.off(`show.load-data::${this.model}`, this.loadData);
    },

    methods: {
        loadData() {
            this.loading = true;
            this.$axios
                .get(`/${this.model}/${this.$route.params.id}/show`)
                .then((response) => {
                    this.data = response.data;
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                    this.loading = false;
                });
        },

        openUploadCountriesAndPackagesModal() {
            this.emitter.emit(`show::UploadCountriesWithPackagesModal`, this.data.id);
        }
    },
};
</script>
