import * as getters from './getters.js';
import * as mutations from './mutations.js';
import * as actions from './actions.js';

const state = {
    model: 'gift-cards',
    items: [],

    constants: {
        status: {
            1: {
                id: 1,
                label: 'New',
                colors: {
                    bg: 'bg-blue-400',
                    text: 'text-white'
                }
            },
            2: {
                id: 2,
                label: 'Used',
                colors: {
                    bg: 'bg-yellow-400',
                    text: 'text-white'
                }
            },
            3: {
                id: 3,
                label: 'Finished',
                colors: {
                    bg: 'bg-emerald-400',
                    text: 'text-white'
                }
            },
            4: {
                id: 4,
                label: 'Error',
                colors: {
                    bg: 'bg-red-400',
                    text: 'text-white'
                }
            },
        },
    },

    pagination: {
        limit: 25,
    },

    loading: false,

    viewIndexData: {
        pageTitle: 'Gift Cards',
        tableHeads: [
            {title: 'Code', key: 'code'},
            {title: 'Amount', key: 'amount'},
            {title: 'Status', key: 'status', type: 'badge', constant: 'status'},
        ],
        tableProps: {
            create: true,
            show: true,
            destroy: false,
            edit: false,
            group: false,
            search: false,
            downloadExcel: false,
            itemsLimit: true
        }
    },

    viewCreateData: {
        pageTitle: 'Create gift card',
    },

    viewDetailData: {
        pageTitle: 'Gift card details',
        fields: [
            {text: 'Code', key: 'code'},
            {text: 'Initial Amount', key: 'initial_amount'},
            {text: 'Balance', key: 'balance'},
            {text: 'Status', key: 'status', type: 'badge', constant: 'status'},
        ],
    },

    viewEditData: {
        pageTitle: 'Edit Country package',
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};