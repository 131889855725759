import CitiesIndex from "@/views/cities/Index";
import CitiesCreate from "@/views/cities/Create";
import CitiesEdit from "@/views/cities/Edit";
import CitiesDetail from "@/views/cities/Detail";

export default [
  {
    path: "/cities",
    name: "Cities",
    component: CitiesIndex,
  },

  {
    path: "/cities/create",
    name: "CitiesCreate",
    component: CitiesCreate,
  },

  {
    path: "/cities/:id/show",
    name: "CitiesShow",
    component: CitiesDetail,
  },

  {
    path: "/cities/:id/edit",
    name: "CitiesEdit",
    component: CitiesEdit,
  },
];