<template>
    <app-layout>
        <detail-view
            :model="model"
        />
        <div
            class="shadow-sm bg-white rounded-lg py-4 px-6 w-full md:w-1/2 mt-6"
        >
            <p class="mb-2">Charges history</p>
            <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left text-gray-500">
                    <thead class="text-slate-400 border-b border-slate-100 text-sx font-normal">
                        <tr>
                            <th scope="col" class="p-4">
                                Order
                            </th>
                            <th scope="col" class="p-4">
                                Email
                            </th>
                            <th scope="col" class="p-4">
                                Used Amount
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="log in fields" :key="log.id" class="bg-white border-b">
                        <td
                            class="p-4"
                        >
                            {{ log.product_id }}
                        </td>
                        <td
                            class="px-6 py-4"
                        >
                            {{ log.user_email }}
                        </td>
                        <td
                            class="px-6 py-4"
                        >
                            {{ log.used_amount }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "../../layouts/AppLayout.vue";
import DetailView from "../../components/views/detail/Index.vue";
import {mapGetters} from "vuex";

export default {
    name: "GiftCardsDetail",

    components: {
        DetailView,
        AppLayout,
    },
    data() {
        return {
            fields: null
        }
    },
    computed: {
        ...mapGetters({
            model: 'gift-cards/getModel',
        })
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            this.$axios.get(`/${this.model}/${this.$route.params.id}/get-logs`)
                .then(res => {
                    this.fields = res.data;
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                    this.loading = false;
                });
        },
    }
};
</script>
