<template>
  <div class="flex items-center mb-4">
    <input
        :name="name"
        :disabled="disable"
        type="radio"
        :class="inputStyles()"
        :value="value"
        @change="$emit('update:modelValue', $event.target.value)"
        :checked="radioChecked()"
    >
    <label :class="labelStyles()" v-if="label">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "InputRadio",
  props: {
    size: {
      type: String,
      default() {
        return 'base'
      }
    },
    disable: {
      type: Boolean,
      default() {
        return false
      }
    },
    label: {
      type: String,
      default() {
        return ''
      }
    },
    name: {
      type: String,
      default() {
        return ''
      }
    },
    modelValue: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
  },
  methods: {
    inputStyles() {
      let styles = `w-4 h-4 border-none`
      if (this.size === 'base') {
        styles += ` w-4 h-4`
      }
      if (this.size === 'sm') {
        styles += ` w-6 h-6`
      }
      if (this.size === 'lg') {
        styles += ` w-8 h-8`
      }
      return styles
    },
    labelStyles() {
      let labelStyle = `ml-2 block`
      if (this.disable) {
        labelStyle += ` text-gray-500`
      }
      if (this.size === 'base') {
        labelStyle += ` text-base p-2`;
      }
      if (this.size === 'sm') {
        labelStyle += ` text-sm p-2`;
      }
      if (this.size === 'lg') {
        labelStyle += ` text-lg p-2`
      }

      return labelStyle
    },
    radioChecked() {
      let checked = false
      if(this.value === this.modelValue) {
        checked = true
      }
      return checked
    }
  },
}
</script>

<style scoped>

</style>