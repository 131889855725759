<template>
    <app-layout>
        <index-view
            :model="model"
        />
    </app-layout>
</template>

<script>
import AppLayout from "../../layouts/AppLayout.vue";
import {mapGetters} from "vuex";
import IndexView from "../../components/views/index/Index.vue";

export default {
    name: "OrdersIndex",

    components: {
        IndexView,
        AppLayout,
    },

    computed: {
        ...mapGetters({
            model: 'orders/getModel',
        })
    },
}
</script>

<style scoped>

</style>