<template>
  <nav :class="navbarStyles()">
    <div @click.prevent="$store.dispatch('setHideSidebar', !hideSidebar)" :class="burgerStyles()">
      <fa-icon class="" icon="bars"></fa-icon>
    </div>
    <div class="navbar__title">
      <h1 class="font-semibold">{{ navTitle }}</h1>
    </div>

    <div class="navbar__actions">
      <template v-if="user">
        <div class="navbar__profile h-10 flex justify-between items-center cursor-pointer">
          <h1 class="mr-3">
            {{ user.first_name }} {{ user.last_name }}
          </h1>
          <img
            src="../../assets/images/person-male-1.svg"
            alt="Profile"
            class="h-full"
          >
        </div>
      </template>
    </div>
  </nav>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NavbarIndex",

  computed: {
    ...mapGetters({
      user: 'getUser',
      navTitle: 'getNavTitle',
      hideSidebar: "getHideSidebar",
    })
  },

  methods: {
    navbarStyles() {
      let styles = "navbar bg-white h-14 shadow-sm flex justify-between px-6 items-center gap-6 sm:gap-0";

      return styles;
    },
    burgerStyles() {
      let styles = "sm:hidden h-14 flex justify-between items-center";

      return styles;
    },
  }
}
</script>

<style scoped>

</style>