import * as getters from './getters.js';
import * as mutations from './mutations.js';
import * as actions from './actions.js';

const state = {
    model: 'reports',
    items: [],

    constants: {},

    pagination: {
        limit: 25,
    },

    loading: false,

    viewIndexData: {
        pageTitle: 'Reports',
        tableHeads: [
            {title: 'Order Number', key: 'order_id'},
            {title: 'Order Date', key: 'created_at'},
            {title: 'Start Date', key: 'from_date'},
            {title: 'Number of Days', key: 'number_of_days'},
            {title: 'End Date', key: 'to_date'},
            {title: 'Customer name', key: 'first_name'},
            {title: 'Total amount', key: 'total_amount'},
            {title: 'Shipping $0/$20/$89', key: 'shipping_price'},
            {title: 'Coupon used', key: 'coupon_code'},
            {title: 'CC Fee', key: ''},
            {title: 'Insurance amount', key: 'insurance_amount'},
            {title: 'Package location', key: 'country_package_name'},
            {title: 'Pickup location', key: 'pickup_location_name'},
            {title: 'Employee code', key: 'customer_api_id'},
            {title: 'Device number', key: ''},
            {title: 'Email', key: 'email'},
            {title: 'Number of Devices', key: 'number_of_devices'},
            {title: 'Commission amount', key: 'plan_amount'},
        ],
        tableProps: {
            create: false,
            show: false,
            destroy: false,
            edit: false,
            group: false,
            search: true,
            downloadExcel: true,
            itemsLimit: true
        }
    },

    viewCreateData: {
        pageTitle: 'Create country package',
    },

    viewDetailData: {
        pageTitle: 'Country package details',
        fields: [
            {text: 'Country', key: 'name', belongs_to: 'country'},
            {text: 'Name', key: 'name'},
            {text: 'Price', key: 'price'},
            {text: 'Note', key: 'note'},
            {text: 'Index', key: 'index'},
        ],
    },

    viewEditData: {
        pageTitle: 'Edit Country package',
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};