<template>
    <app-layout>
        <create-view
            :model="model"
        />
    </app-layout>
</template>

<script>
import AppLayout from "../../../layouts/AppLayout.vue";
import CreateView from "../../../components/views/create/Index.vue";
import {mapGetters} from "vuex";

export default {
    name: "HeadingCreate",

    components: {
        CreateView,
        AppLayout
    },

    computed: {
        ...mapGetters({
            model: 'headings/getModel',
        })
    },
}
</script>

<style lang="sass">

</style>