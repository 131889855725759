<template>
    <div class="mb-4">
        <h1 class="font-medium text-xs text-slate-400">
            {{ field.text }}
        </h1>

        <div :class="styleClasses">
            {{ value }}
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";

export default {
    name: "DetailDateField",

    props: {
        model: {
            type: String,
            default() {
                return null;
            },
        },
        field: {
            type: Object,
            default() {
                return null;
            }
        },
        item: {
            type: Object,
            default() {
                return null;
            }
        },
    },
    computed: {
        ...mapState({
            constants(state, getters) {
                return getters[`${this.model}/getConstants`]
            },
        }),

        styleClasses() {
            let styles = ` text-base`;
            return styles;
        },

        value() {
            return moment(this.item[this.field.key]).format(this.field.format)
        }
    },
}
</script>

<style scoped>

</style>