<template>
  <app-layout>
<!--    <template v-if="loading">-->
<!--      <div class="flex justify-center">-->
<!--        <pacman-loader-->
<!--          :loading="loading"-->
<!--          color="#3b82f6"-->
<!--        />-->
<!--      </div>-->
<!--    </template>-->
<!--    <template v-if="!loading">-->
<!--      <table-index-->
<!--        :model="model"-->
<!--        :heads="tableHeads"-->
<!--        :items="items"-->
<!--        :currentPage="currentPage"-->
<!--        :total="total"-->
<!--        :pages="pages"-->
<!--        :perPage="perPage"-->
<!--        @changePage="loadData"-->
<!--      />-->
<!--    </template>-->
      <index-view
        :model="model"
      />
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import IndexView from "../../components/views/index/Index.vue";
import { mapGetters } from "vuex";

export default {
    name: "UsersIndex",

  components: {
      IndexView,
    AppLayout,
  },
    computed: {
        ...mapGetters({
            model: 'users/getModel',
        })
    },
}
</script>

<style scoped>

</style>