<template>
  <!--  Card Info --------------------------------->
  <div class="container mx-auto py-4 mb-4">
    <h1 class="text-2xl mb-2">
      Card Info
    </h1>

    <div class="columns-4">
      <card-short-info
        title="Card Title"
        value="0"
        action-text="View"
      />
      <card-short-info
        title="Card Title"
        value="50"
        action-text="View"
      />
      <card-short-info
        title="Card Title"
        value="100%"
        action-text="View"
      />
      <card-short-info
        title="Card Title"
        value="$3675"
        action-text="View"
      />
    </div>
  </div>

  <!--  Table -------------------------------------->
  <div class="container mx-auto py-4 mb-4">
    <h1 class="text-2xl mb-2">
      Table
    </h1>

    <table-index
      :heads="tableHeads"
      :items="tableItems"
    />
  </div>

  <!--  Forms -------------------------------------->
  <div class="container mx-auto py-4 mb-4">
    <h1 class="text-2xl mb-2">
      Forms
    </h1>

    <input-text
      label="Input text"
      placeholder="Input text"
    />

    <input-email
      label="Input email"
      placeholder="Input email"
    />

    <input-password
      label="Input password"
      placeholder="Input password"
    />

    <div class="columns-3">
      <div>
        <input-checkbox
          label="Input checkbox 1"
        />

        <input-checkbox
          label="Input checkbox 2"
        />

        <input-checkbox
          label="Input checkbox 3"
        />
      </div>

      <div>
        <input-radio
          label="Input radio 1"
          name="input-radio"
        />
        <input-radio
          label="Input radio 2"
          name="input-radio"
        />
        <input-radio
          label="Input radio 3"
          name="input-radio"
        />
      </div>

      <div>
        <input-toggle
          label="Input toggle 1"
        />
        <input-toggle
          label="Input toggle 2"
        />
        <input-toggle
          label="Input toggle 3"
        />
      </div>
    </div>
  </div>

</template>
<script>
import CardShortInfo from "@/components/card-info/CardShortInfo";
import TableIndex from "@/components/ui/table/TableIndex";
import InputText from "@/components/ui/inputs/InputText";
import InputEmail from "@/components/ui/inputs/InputEmail";
import InputPassword from "@/components/ui/inputs/InputPassword";
import InputCheckbox from "@/components/ui/inputs/InputCheckbox";
import InputRadio from "@/components/ui/inputs/InputRadio";
import InputToggle from "@/components/ui/inputs/InputToggle";

export default {
  components: {InputToggle, InputRadio, InputCheckbox, InputPassword, InputEmail, InputText, TableIndex, CardShortInfo},

  data() {
    return {
      tableHeads: [
        {title: 'First name', key: 'first_name'},
        {title: 'Last name', key: 'last_name'},
        {title: 'Email', key: 'email'},
      ],
      tableItems: [
        {first_name: 'First name 1', last_name: 'Last name 1', email: 'email_1@example.com'},
        {first_name: 'First name 2', last_name: 'Last name 2', email: 'email_2@example.com'},
        {first_name: 'First name 3', last_name: 'Last name 3', email: 'email_3@example.com'},
        {first_name: 'First name 4', last_name: 'Last name 4', email: 'email_4@example.com'},
        {first_name: 'First name 5', last_name: 'Last name 5', email: 'email_5@example.com'},
        {first_name: 'First name 6', last_name: 'Last name 6', email: 'email_6@example.com'},
      ],
    }
  }
}
</script>