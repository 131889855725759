import DevicesIndex from "@/views/devices/Index";
import DevicesCreate from "@/views/devices/Create";
import DevicesEdit from "@/views/devices/Edit";
import DevicesDetail from "@/views/devices/Detail";

export default [
  {
    path: "/devices",
    name: "Devices",
    component: DevicesIndex,
  },

  {
    path: "/devices/create",
    name: "DevicesCreate",
    component: DevicesCreate,
  },

  {
    path: "/devices/:id/show",
    name: "DevicesShow",
    component: DevicesDetail,
  },

  {
    path: "/devices/:id/edit",
    name: "DevicesEdit",
    component: DevicesEdit,
  },
];