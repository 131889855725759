import PagesHomeIndex from "@/views/pages/pages-home/Index";
import PagesHomeCreate from "@/views/pages/pages-home/Create";
import PagesHomeEdit from "@/views/pages/pages-home/Edit";
import PagesHomeDetail from "@/views/pages/pages-home/Detail";

export default [
  {
    path: "/pages-home",
    name: "PagesHome",
    component: PagesHomeIndex,
  },

  {
    path: "/pages-home/create",
    name: "PagesHomeCreate",
    component: PagesHomeCreate,
  },

  {
    path: "/pages-home/:id/show",
    name: "PagesHomeShow",
    component: PagesHomeDetail,
  },

  {
    path: "/pages-home/:id/edit",
    name: "PagesHomeEdit",
    component: PagesHomeEdit,
  },
];