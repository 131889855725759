import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import _ from "lodash";
import moment from 'moment';
import mitt from 'mitt';

import {axiosPlugin} from "@/plugins/axios";
import toast from "@/plugins/toast";

import 'animate.css';
import "./assets/styles/tailwind.css";
import "./assets/styles/main.scss";

import InputText from "@/components/ui/inputs/InputText";
import InputEmail from "@/components/ui/inputs/InputEmail";
import InputPassword from "@/components/ui/inputs/InputPassword";
import InputSelect from "@/components/ui/inputs/InputSelect";
import InputNumber from "@/components/ui/inputs/InputNumber";
import InputToggle from "@/components/ui/inputs/InputToggle";
import InputDate from "@/components/ui/inputs/InputDate";
import InputDateTime from "@/components/ui/inputs/InputDateTime";
import InputBelongsTo from "@/components/ui/inputs/InputBelongsTo";

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

/* import the fontawesome core */
import {library} from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import {
    faHouseChimney,
    faBagShopping,
    faChartBar,
    faCalendarDays,
    faListCheck,
    faBoxesStacked,
    faUserGroup,
    faGear,
    faArrowRightFromBracket,
    faEye,
    faPen,
    faTrash,
    faHouseSignal,
    faEarthAmerica,
    faCity,
    faLocationDot,
    faUserAstronaut,
    faTicket,
    faBars,
    faArrowLeft,
    faArrowRight,
    faLink,
    faFlag,
    faGears,
    faSortDown,
    faXmark,
    faBuilding,
    faBuildingColumns,
    faBoxes,
    faWifi,
    faPager,
    faRadio

} from "@fortawesome/free-solid-svg-icons";

/* import font awesome icon component */
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

/* add icons to the library */
library.add(
    faHouseChimney,
    faBagShopping,
    faChartBar,
    faCalendarDays,
    faListCheck,
    faBoxesStacked,
    faUserGroup,
    faGear,
    faArrowRightFromBracket,
    faEye,
    faPen,
    faTrash,
    faHouseSignal,
    faEarthAmerica,
    faCity,
    faLocationDot,
    faUserAstronaut,
    faTicket,
    faBars,
    faArrowLeft,
    faArrowRight,
    faLink,
    faFlag,
    faGears,
    faSortDown,
    faXmark,
    faBuilding,
    faBuildingColumns,
    faBoxes,
    faWifi,
    faPager,
    faRadio,
);

const app = createApp(App);

const emitter = mitt();

app.config.globalProperties.emitter = emitter;
app.config.globalProperties._ = _;
app.config.globalProperties.moment = moment;

app.component('v-select', vSelect);

app.component("input-select", InputSelect);
app.component("input-text", InputText);
app.component("input-email", InputEmail);
app.component("input-password", InputPassword);
app.component("input-number", InputNumber);
app.component("input-toggle", InputToggle);
app.component("input-date", InputDate);
app.component("input-date-time", InputDateTime);
app.component("input-belongs-to", InputBelongsTo);

app
    .use(store)
    .use(router)
    .use(axiosPlugin)
    .use(toast)
    .component("fa-icon", FontAwesomeIcon)
    .mount("#app");
