<template>
    <modal
        :modal-id="modelId"
    >
        <template v-slot:title>
            <h1 class="font-medium">
                Select group
            </h1>
        </template>

        <div class="flex">
            <input-select
                :options="dealerGroups"
                label="Dealer groups"
                @blur="loadDealers"
                @input="input"
                @selected="selectedDealer"
            />
        </div>

        <template v-slot:actions>
            <btn
                class="mr-4"
                text="Save"
                color="success"
                :loading="loading"
                @click="submit"
            />
            <btn
                text="Cancel"
                color="dark"
                @click="hideModal"
            />
        </template>
    </modal>
</template>

<script>
import Modal from "@/components/ui/modal/Modal";
import Btn from "@/components/ui/buttons/Btn";
import _ from "lodash";
import InputSelect from "@/components/ui/inputs/InputSelect";

export default {
    name: "SelectDealerGroupModal",

    components: {InputSelect, Modal, Btn},

    props: {
        model: {
            type: String,
            default() {
                return '';
            }
        },
    },

    data() {
        return {
            modelId: `${this.model}SelectDealerGroupModal`,
            loading: false,

            dealerGroups: [],
            selectedDealerGroup: null,
            dealerGroupSearchKeywords: '',
        }
    },

    created() {
        this.emitter.on(`show::${this.modelId}`, this.showModal);
        this.emitter.on(`hide::${this.modelId}`, this.hideModal);

        this.loadDealers();
    },

    beforeUnmount() {
        this.emitter.off(`show::${this.modelId}`, this.showModal);
        this.emitter.off(`hide::${this.modelId}`, this.hideModal);
    },

    methods: {
        showModal() {
            this.emitter.emit(`modal::show::${this.modelId}`);
        },

        hideModal() {
            this.emitter.emit(`modal::hide::${this.modelId}`);
        },

        submit() {
            if (this.selectedDealerGroup && this.selectedDealerGroup.value) {
                this.emitter.emit(`${this.model}::group`, {
                    dealer_group: this.selectedDealerGroup
                });

                this.hideModal();
            }
        },

        loadDealers() {
            this.$axios.get(`/dealer-groups/search`, {
                params: {
                    keywords: this.dealerGroupSearchKeywords,
                    limit: 10
                }
            })
                .then(res => {
                    this.dealerGroups = [];

                    res.data.forEach(item => {
                        this.dealerGroups.push({
                            value: item.id,
                            name: item.name,
                        });
                    });

                    this.dealerGroupSearchKeywords = '';
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                });
        },

        selectedDealer(dealerGroup) {
            this.selectedDealerGroup = dealerGroup
        },

        input: _.debounce(function (e) {
            if (e && e.target.value) {
                this.dealerGroupSearchKeywords = e.target.value;

                this.loadDealers();
            }
        }, 300),
    }
}
</script>

<style scoped>

</style>