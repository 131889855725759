<template>
    <div class="input-toggle flex items-center w-full mb-6">
        <label
            class="cursor-pointer"
        >
            <span :class="labelStyles()" v-if="label">
                {{ label }}
            </span>

            <div class="relative">
                <input
                    :disabled="disable"
                    type="checkbox"
                    class="sr-only"
                    @change="$emit('update:modelValue', $event.target.checked)"
                />
                <div class="dot-bg w-12 h-7 bg-gray-300 rounded-full shadow-inner"></div>
                <div class="dot absolute w-6 h-6 bg-white rounded-full left-0.5 top-0.5 transition"></div>
            </div>

            <p class="mt-2 text-sm text-red-600" v-if="errorMessage">
                {{ errorMessage }}
            </p>
        </label>
    </div>
</template>

<script>
export default {
    name: "InputToggle",
    props: {
        disable: {
            type: Boolean,
            default() {
                return false
            }
        },
        label: {
            type: String,
            default() {
                return ''
            }
        },
        modelValue: {
            type: Boolean,
            default() {
                return false
            }
        },
        errorMessage: {
            type: String,
            default() {
                return "";
            },
        },
    },
    methods: {
        labelStyles() {
            let labelStyle = `block mb-2 text-sm `;
            if (this.errorMessage) {
                labelStyle += ` text-red-600 `;
            }
            return labelStyle;
        },
    }
}
</script>

<style lang="scss">
    .input-toggle {
        input:checked ~ .dot {
            transform: translateX(85%);
        }

        input:checked ~ .dot-bg {
            background: #3b82f6;
        }
    }
</style>