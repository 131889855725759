<template>
    <a :class="sidebarBrandStyles" href="/" @click.prevent="$store.dispatch('setHideSidebar', !hideSidebar)">
        <div :class="sidebarBrandLogoStyles">
            <img class="" src="../../assets/images/logo.png" alt="Brand"/>
        </div>

        <div v-show="!hideSidebar" class="brand__label flex justify-between w-full">
            <h1 class="text-base font-semibold text-gray-600 m-0">
                {{ brand }}
            </h1>
            <div>
                <fa-icon class="ml-6 sm:hidden " icon="arrow-left"/>
            </div>
        </div>
    </a>
</template>

<script>
import {mapGetters} from "vuex";
import appConfig from "../../configs/app.js"

export default {
    name: "SidebarBrand",

    data() {
        return {
            brand: appConfig.name,
        }
    },

    computed: {
        ...mapGetters({
            hideSidebar: "getHideSidebar",
        }),
        sidebarBrandStyles() {
            let styles = "brand flex items-center mb-8";

            return styles;
        },
        sidebarBrandLogoStyles() {
            let styles = "brand__logo flex justify-center items-center w-10 h-10";

            if (this.hideSidebar) {
                styles += " ";
            } else {
                styles += " mr-4";
            }

            return styles;
        },
    },
};
</script>

<style scoped></style>
