import * as getters from './getters.js';
import * as mutations from './mutations.js';
import * as actions from './actions.js';

const state = {
    model: 'headings',
    items: [],

    constants: {},

    pagination: {
        limit: 20,
    },

    loading: false,

    viewIndexData: {
        pageTitle: 'Headings',
        tableHeads: [
            {title: 'Heading', key: 'heading'},
            {title: 'Subheading', key: 'subheading'},
            {title: 'Subheading JP', key: 'subheading_jp'},
            {title: 'Heading JP', key: 'heading_jp'},
        ],
        tableProps: {
            create: true,
            show: true,
            destroy: true,
            edit: true,
            group: false,
            search: false,
            itemsLimit: false
        }
    },

    viewCreateData: {
        pageTitle: 'Create heading',
    },

    viewDetailData: {
        pageTitle: 'Heading details',
        fields: [
            {text: 'Heading', key: 'heading'},
            {text: 'Subheading', key: 'subheading'},
            {title: 'Subheading JP', key: 'subheading_jp'},
            {title: 'Heading JP', key: 'heading_jp'},
        ],
    },

    viewEditData: {
        pageTitle: 'Edit heading',
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};