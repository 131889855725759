<template>
    <modal
        :modal-id="modelId"
    >
        <template v-slot:title>
            <h1 v-if="active" class="font-medium">
                Confirm action
            </h1>
            <h1 v-else class="font-medium">
                Confirm delete
            </h1>
        </template>

        <h1 class="text-xl" v-if="active">What do you want to do with item?</h1>
        <h1 class="text-xl" v-else>Delete this item?</h1>

        <template v-slot:actions>
            <btn
                class="mr-4"
                text="Activate"
                color="green"
                size="sm"
                :loading="loading"
                @click="activate"
                v-if="active"
            />
            <btn
                class="mr-4"
                text="Delete"
                color="red"
                size="sm"
                :loading="loading"
                @click="destroy"
            />
            <btn
                text="Cancel"
                size="sm"
                @click="hideModal"
            />
        </template>
    </modal>
</template>

<script>

import Modal from "../../modal/Modal.vue";
import Btn from "../../buttons/Btn.vue";

export default {
    name: "TableConfirmDeleteModal",

    components: {
        Btn,
        Modal

    },

    props: {
        model: {
            type: String,
            default() {
                return '';
            }
        },
        active: {
            type: Boolean,
            default() {
                return false;
            }
        },
        imageSrc: {
            type: String,
            default() {
                return ''
            }
        }
    },

    data() {
        return {
            modelId: `${this.model}ConfirmDeleteModal`,
            itemId: null,
            loading: false,
        }
    },

    created() {
        this.emitter.on(`show::${this.modelId}`, this.showModal);
        this.emitter.on(`hide::${this.modelId}`, this.hideModal);
    },

    beforeUnmount() {
        this.emitter.off(`show::${this.modelId}`, this.showModal);
        this.emitter.off(`hide::${this.modelId}`, this.hideModal);
    },

    methods: {
        activate() {
            this.$axios.get("pages/home-page/get-image", {
                params: {
                    image_src: this.imageSrc
                }
            })
                .then(res => {
                    console.log(res)
                    this.$toast.success('Image changed successfully!');
                    this.hideModal();
                })
        },
        showModal(id) {
            this.itemId = id;
            this.emitter.emit(`modal::show::${this.modelId}`);
        },
        hideModal() {
            this.emitter.emit(`modal::hide::${this.modelId}`);
        },

        destroy() {
            this.loading = true;

            this.$axios.delete(`${this.model}/${this.itemId}/delete`)
                .then(res => {
                    if (res) {
                        this.$store.dispatch(`${this.model}/deleteItemById`, res.data.id);
                        this.$toast.success('Item deleted successfully!');
                        this.hideModal();
                        this.loading = false;
                    }
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);

                    this.loading = false;
                });
        }
    }
}
</script>

<style scoped>

</style>