import * as getters from './getters.js';
import * as mutations from './mutations.js';
import * as actions from './actions.js';

const state = {
    model: 'pages/home-page',
    items: [],

    constants: {},

    pagination: {
        limit: 20,
    },

    loading: false,

    viewIndexData: {
        pageTitle: 'Coupons',
        tableHeads: [
            {title: 'Name', key: 'coupon_name'},
            {title: 'Amount', key: 'discount_amount'},
            {title: 'Type', key: 'discount_type'},
            {title: 'Country', key: 'country_name'},
        ],
        tableProps: {
            create: true,
            show: true,
            destroy: true,
            edit: true,
            group: false,
            search: true,
            itemsLimit: false
        }
    },

    viewCreateData: {
        pageTitle: 'Create coupon',
    },

    viewDetailData: {
        pageTitle: 'Coupon details',
        fields: [
            {text: 'Name', key: 'coupon_name'},
            {text: 'Amount', key: 'discount_amount'},
            {text: 'Type', key: 'discount_type'},
            {text: 'Valid from', key: 'valid_from'},
            {text: 'Valid till', key: 'valid_till'},
            {text: 'Rental days', key: 'rental_days'},
            {text: 'Is special coupon', key: 'is_special_coupon'},
            {text: 'Day 15 to 30 price', key: 'day_15_to_30_price'},
            {text: 'Day 31 plus price', key: 'day_31_plus_price'},
        ],
    },

    viewEditData: {
        pageTitle: 'Edit coupon',
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};