<template>
  <app-layout>
    <index-view
        :model="model"
    />
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import IndexView from "@/components/views/index/Index.vue";
import {mapGetters} from "vuex";

export default {
  name: "AffiliatesIndex",

  components: {IndexView, AppLayout},

  computed: {
      ...mapGetters({
          model: 'affiliates/getModel',
      })
  }
}
</script>

<style scoped>

</style>