<template>
  <div class="flex items-center mb-4">
    <input
        type="checkbox"
        :class="inputStyles()"
        :disabled="disabled"
        @change="$emit('update:modelValue', $event.target.checked)"
    >
    <label v-if="label" :class="labelStyles()">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "InputCheckbox",
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    label: {
      type: String,
      default() {
        return ''
      }
    },
    size: {
      type: String,
      default() {
        return 'base'
      }
    },
    modelValue: {
      type: String,
      default() {
        return ''
      }
    },

  },
  methods: {
    inputStyles() {
      let styles = `rounded border-none`
      if (this.size === 'base') {
        styles += ` w-4 h-4`
      }
      if (this.size === 'sm') {
        styles += ` w-6 h-6`
      }
      if (this.size === 'lg') {
        styles += ` w-8 h-8`
      }
      return styles
    },
    labelStyles() {
      let labelStyle = `ml-3`
      if (this.disable) {
        labelStyle += ` text-gray-500`
      }
      if (this.size === 'base') {
        labelStyle += ` text-base p-2`;
      }
      if (this.size === 'sm') {
        labelStyle += ` text-sm p-2`;
      }
      if (this.size === 'lg') {
        labelStyle += ` text-lg p-2`
      }

      return labelStyle
    },
  }
}
</script>

<style scoped>

</style>