<template>
    <div class="mb-4">
        <h1 class="font-medium text-xs text-slate-400">
            {{ field.text }}
        </h1>

        <div :class="styleClasses">
            {{ value }}
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "DetailBadgeField",

    props: {
        model: {
            type: String,
            default() {
                return null;
            },
        },
        field: {
            type: Object,
            default() {
                return null;
            }
        },
        item: {
            type: Object,
            default() {
                return null;
            }
        },
    },
    computed: {
        ...mapState({
            constants(state, getters) {
                return getters[`${this.model}/getConstants`]
            },
        }),

        styleClasses() {
            let constant = this.constants[this.field.constant][this.item[this.field.key]];
            let styles = ` lowercase text-xs font-medium inline-block px-2 pt-0.5 pb-1 rounded-full`;

            if (constant.colors) {
                if (constant.colors.bg) {
                    styles += ` ${constant.colors.bg}`;
                }
                if (constant.colors.text) {
                    styles += ` ${constant.colors.text}`;
                }
            } else {
                styles += ` bg-slate-300`;
            }

            return styles;
        },

        value() {
            return this.constants[this.field.constant][this.item[this.field.key]].label
        }
    },
}
</script>

<style scoped>

</style>