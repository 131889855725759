<template>
    <app-layout>
        <template v-for="(field, key) in fields" :key="key">
            <component
                :options="field.options"
                :belongs-to="field.belongs_to"
                :label="field.title"
                :type="field.type"
                :labelText="field.title"
                :is="`input-${field.type}`"
                :error-message="errors && errors[field.key] ? errors[field.key][0] : ''"
                v-model="field.value"
            />
        </template>

        <div class="flex">
            <input-select
                :options="dealers"
                label="Dealers"
                @blur="loadDealers"
                @input="input"
                @selected="selectedDealer"
            />
        </div>

        <div v-if="selectedDealers.length" class="mb-6 bg-gray-100 w-full sm:w-1/2 max-w-3xl px-4 pt-2 rounded">
            <template v-for="(selectedDealer, key) in selectedDealers" :key="`selected-dealer-${key}`">
                <div class="pb-2 text-sm flex justify-between">
                    <span>{{ selectedDealer.name }}</span>

                    <fa-icon
                        class="cursor-pointer"
                        icon="xmark"
                        @click="spliceSelectedDealer(key)"
                    />
                </div>
            </template>
        </div>

        <btn
            text="Update"
            color="success"
            @click="submit"
        />
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import Btn from "@/components/ui/buttons/Btn";
import _ from "lodash";

export default {
    name: "DealerGroupsEdit",
    components: {Btn, AppLayout},
    data() {
        return {
            model: 'dealer-groups',
            pageTitle: 'Edit dealer group',
            fields: {},

            dealers: [],
            selectedDealers: [],
            dealerSearchKeywords: '',

            errors: null,
            loading: false,
        };
    },

    created() {
        this.loadData();
        this.loadDealers();
        this.loadDealersByGroupId(this.$route.params.id);

        this.$store.dispatch('setNavTitle', this.pageTitle);
    },

    methods: {
        loadData() {
            this.loading = true;
            this.$axios.get(`/${this.model}/${this.$route.params.id}/edit`)
                .then(res => {
                    this.fields = res.data;
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                    this.loading = false;
                });
        },

        submit() {
            this.loading = true;
            let formData = new FormData;

            for (let key in this.fields) {
                if (this.fields[key].value) {
                    formData.set(this.fields[key].key, this.fields[key].value);
                }
            }

            let dealerIds = [];
            this.selectedDealers.forEach(selectedDealer => {
                dealerIds.push(selectedDealer.value);
            });

            formData.set('dealer_ids', JSON.stringify(dealerIds));

            this.$axios.post(`/${this.model}/${this.$route.params.id}/update`, formData)
                .then(() => {
                    this.$router.push(`/${this.model}`);
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);

                    if (e.status === 422) {
                        this.errors = e.data.errors;
                    }

                    this.loading = false;
                });
        },

        loadDealers() {
            this.$axios.get(`/dealers/search`, {
                params: {
                    keywords: this.dealerSearchKeywords,
                    limit: 10
                }
            })
                .then(res => {
                    this.dealers = [];
                    res.data.forEach(item => {
                        this.dealers.push({
                            value: item.id,
                            name: item.name,
                        });
                    });

                    this.dealerSearchKeywords = '';
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                });
        },

        selectedDealer(dealer) {
            let findDealer = this.selectedDealers.find(selectedDealer => selectedDealer.value === dealer.value);

            if (!findDealer) {
                this.selectedDealers.push(dealer);
            }
        },

        input: _.debounce(function (e) {
            if (e && e.target.value) {
                this.dealerSearchKeywords = e.target.value;

                this.loadDealers();
            }
        }, 300),

        loadDealersByGroupId(id) {
            this.$axios.get(`/${this.model}/${id}/dealers`)
                .then(res => {
                    res.data.forEach(item => {
                        this.selectedDealers.push({
                            value: item.id,
                            name: item.name,
                        });
                    });
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                });
        },

        spliceSelectedDealer(key) {
            this.selectedDealers.splice(key, 1);
        }
    }
};
</script>
